import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../../src/common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

export default function EmployeeStatusReportPop(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    record_type_status: string
    first_name: string
    name: string
    phone_number: string
    last_name: string
    user_status: string
    employeeId: any
    roleName: any
    role: string
    email: string
    work_city: string
    firstName: string
    full_name: string
    employee_type: string
    employee_status: string
    date_range: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    business_unit_id: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [mainTableData, setMainTableData] = React.useState<any | []>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [country, setCountry] = useState<any | []>([])
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [flag1, setFlag1] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [resetData1, setResetData] = useState<any | []>([])
  const [statesId, setStatesId] = useState<any | []>([])
  const [selectedItem, setSelectedItem] = useState('')
  const [EmployeeStatus, setEmployeeStatus] = useState('')
  const [selectConsultant, setSelectConsultant] = useState([])
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [dateRange, setDateRange] = useState<any | []>([null, null])
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [search, setSearch] = useState('')
  const [search_open, setSearch_Open] = useState(false)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [Reportsdata, setReports_Data] = useState<any | []>([])

  const [perPage, setPerPage] = useState(25)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ])
  const [startDate, endDate] = dateRange
  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id
    setShowBackDrop(false)
  }

  const selectedCountries = async (state: any) => {
    setFlag1(false)
    setCountries(state)
    setUseStateCountryId(state[0]?.id)
    setResetData(state[0]?.full_name)
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        // setBusinessUnitsList(res.data.data)
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...res.data.data])
      }
    }
  }
  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: country})
      setCountry(response && response.data && response.data.data ? response?.data?.data : '')
    }
  }
  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick(1, value)
    }
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)

    handleClick(selected, perPage)
  }

  // const handleClick = async (e: any) => {
  const handleClick = async (page: any, perPage: any) => {
    setSearch_Open(false)
    setFlag(true)
    setShowBackDrop(true)
    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    if (flag1) {
      const data: any = await Apiservices.employeesStatusReport({
        // pagenumber: currentPage,
        // pagesize: itemsPerPage,
        start_date: startDate2,
        end_date: endDate2,
        employee_type: stat2 ? stat2 : '',
        record_type_status: EmployeeStatus ? EmployeeStatus : null,
        business_unit_id: business2 ? business2 : null,
        pagenumber: page,
        pagesize: Number(perPage),
        // keyword: search ? search : '',
      })
      setMainTableData(data?.data?.data)
      setReports_Data(data.data.count.total_count)
      setPages(Math.ceil(data.data.count.total_count / perPage))
    } else {
      const data: any = await Apiservices.employeesStatusReport({
        // pagenumber: currentPage,
        // pagesize: itemsPerPage,
        start_date: startDate2,
        end_date: endDate2,
        employee_type: stat2 ? stat2 : '',
        record_type_status: EmployeeStatus ? EmployeeStatus : null,
        business_unit_id: business2 ? business2 : null,
        pagenumber: page,
        pagesize: Number(perPage),
        // keyword: search ? search : '',
      })
      setMainTableData(data?.data?.data)
      setReports_Data(data.data.count.total_count)
      setPages(Math.ceil(data.data.count.total_count / perPage))
    }
    setShowBackDrop(false)
  }

  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }
  const handleChange2 = (e: any) => {
    setEmployeeStatus(e.target.value)
  }
  const ref: any = useRef<HTMLInputElement>(null)

  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }
  const handleChange1 = async (value: any) => {
    setSearch_Open(true)
    setSearch(value)
    setBusinessUnit([])
    setSelectConsultant([])
    // setValue('business_unit_id', '')
    setSelectedItem('')
    setEmployeeStatus('')
    // ref.current.clear()
    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    const data: any = await Apiservices.employeesStatusReport({
      start_date: startDate2,
      end_date: endDate2,
      keyword: value,
      pagenumber: page,
      pagesize: perPage,
    })
    setMainTableData(data?.data?.data)
    setReports_Data(data.data.count.total_count)
    setPages(Math.ceil(data.data.count.total_count / perPage))
    console.log(data.data, 'ghdghjdghg')
  }
  const resetData = async (e: any) => {
    setFlag1(true)
    setFlag(false)
    setCountry([])
    setDateRange([null])
    setBusinessUnit([])
    setSelectedItem('')
    setEmployeeStatus('')
    setSelectConsultant([])
    // setSearch('')
    setValue('work_city', '')
    // setValue('business_unit_id', '')

    // setUseStateCountryId
    // ref.current.clear()

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    // setResetData(candidate)

    // reset('work_country' == "")
    // setResetData([])
    // state[0]?.full_name
    // const data: any = await Apiservices.employeesStatusReport({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }
  const userName = (candidate: any) => {
    return candidate.charAt(0).toUpperCase() + candidate.slice(1)
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
          //  let test = AllOptions.filter((id: any) => selected.some((x: any) => x.value != id.value))
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }
  // const exporthandleClick = async (e: any) => {
  //   setShowBackDrop(true)
  //   let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   const res: any = await Apiservices.exportEmployeesStatusReport({
  //     start_date: startDate2,
  //     end_date: endDate2,
  //     employee_type: selectedItem ? selectedItem : '',
  //     record_type_status: EmployeeStatus ? EmployeeStatus : '',
  //     keyword: search ? search : '',
  //   })
  //   setShowBackDrop(false)

  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          if (!cde) {
            console.log('cde')
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          console.log(selected, 'selected', BusinessUnitsList, 'BusinessUnitsList')

          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            console.log(test)
            if (test.length == 2) {
              console.log('length')

              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    if (search_open) {
      const res: any = await Apiservices.exportEmployeesStatusReport({
        start_date: startDate2,
        end_date: endDate2,
        business_unit_id: business2 ? business2 : null,
        // employee_type: selectedItem ? selectedItem : '',
        // record_type_status: EmployeeStatus ? EmployeeStatus : '',
        keyword: search ? search : '',
      })
      setShowBackDrop(false)

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res.data.data.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      const res: any = await Apiservices.exportEmployeesStatusReport({
        start_date: startDate2,
        end_date: endDate2,
        business_unit_id: business2 ? business2 : null,
        employee_type: stat2 ? stat2 : '',
        record_type_status: EmployeeStatus ? EmployeeStatus : null,
        // keyword: search ? search : '',
      })

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res.data.data.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    setShowBackDrop(false)
  }

  const sorting5 = (col: any) => {
    console.log(col, '5', typeof col)
    if (order5 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }
  const sorting6 = (col: any) => {
    console.log(col, '5', typeof col)
    if (order6 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      setMainTableData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setMainTableData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>
            Consultant Status Report(Total No. of employees joined and left between selected date
            range)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />
            {loading ? (
              <>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label fw-semibold'>
                          Date Range <span className='fs-9'>(DD-MMM-YYYY)</span> *
                        </label>
                        <DatePicker
                          placeholderText='Date Range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat='dd-MMM-yyyy'
                          onChange={(update: any) => {
                            setDateRange(update)
                          }}
                          isClearable={true}
                          monthsShown={2}
                          className={clsx('form-control')}
                          required
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Type</label>
                        {/* <select
                          value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('employee_type')}
                          onChange={handleChange}
                        >
                          <option value=''>Select Consultant Type</option>
                          <option value='W2'>W2</option>
                          <option value='C2C'>C2C</option>
                          <option value='Internal'>Internal</option>
                          <option value='1099'>1099</option>
                          <option value='India Offshore'>India Offshore</option>
                        </select>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            handleConsultantType(selected)
                            // setSelectConsultant(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={AllOptions}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selectConsultant}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Status</label>
                        <select
                          value={EmployeeStatus}
                          className={clsx(
                            'form-select',
                            errors.employee_status ? 'is-invalid' : ''
                          )}
                          {...register('employee_status')}
                          onChange={handleChange2}
                        >
                          {/* <option value=''>Select Employee Status</option> */}
                          <option value='Active'>Active</option>
                          <option value='Inactive'>Inactive</option>
                          <option value=''>All</option>
                        </select>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('business_unit_id')}
                          onChange={(e: any) => setValue('business_unit_id', e.target.value)}
                        >
                          <option value=''>Select Business Unit</option>
                          {BusinessUnitsList &&
                            BusinessUnitsList.length > 0 &&
                            BusinessUnitsList.map((option: any) => {
                              return (
                                <option value={option.business_unit_id} className='text-capitalize'>
                                  {option.name}
                                </option>
                              )
                            })}
                        </select>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          // value={search}
                          {...register('work_city')}
                          placeholder='Search'
                          onChange={(e) => handleChange1(e.target.value)}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          // onClick={handleClick}
                          onClick={() => handleClick(page, perPage)}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a date range and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive reports-table'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              <th onClick={() => sorting5('business_unit_name')} className='  py-4'>
                                Business Unit{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                Consultant Type{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('record_type_status')} className='  py-4'>
                                Consultant Status{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting6('no_of_employees')} className='  py-4'>
                                No. of Consultants{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {mainTableData?.length > 0 ? (
                              <>
                                {
                                  // mainTableData
                                  //   ?.filter(
                                  //     (each: any) =>
                                  //       each?.first_name
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.adp_associate_id
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.employee_type
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.employee_id
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.last_name
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                                  //       each?.phone_number
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.joining_date
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.record_type_status
                                  //         ?.toLowerCase()
                                  //         ?.includes(search?.toLowerCase()) ||
                                  //       each?.role_name?.toLowerCase()?.includes(search?.toLowerCase())
                                  //   )

                                  mainTableData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.business_unit_name ? each.business_unit_name : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_type ? each.employee_type : '-'}
                                      </td>
                                      <td className=''>
                                        {each.record_type_status ? each.record_type_status : '-'}
                                      </td>
                                      <td className=''>
                                        {each.no_of_employees ? each.no_of_employees : '-'}
                                      </td>
                                    </tr>
                                  ))
                                }
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(mainTableData?.length == null || mainTableData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-stack py-2'>
                {mainTableData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value={Reportsdata}>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div>
                  {mainTableData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
