import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'

export default function ConsultantQBPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)

  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setShow(false)
  }

  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <p className='text-center text-gray-800 fs-6 text-transform-unset'>
            please make sure that the provided consultant is as same as in QB
          </p>

          <div className='row justify-content-center gap-2 py-2'>
            <div className='col-auto'>
              <button className='btn btn-primary' onClick={() => props.ConsultantQBOk('OK')}>
                Ok
              </button>
            </div>
            <div className='col-auto'>
              <button className='btn btn-danger' onClick={props.ConsultantQBClose}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
