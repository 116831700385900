import {useEffect, useState, useRef, useContext} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import {default as Apiservices} from '../../../../common/index'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import moment from 'moment'
import ConsultantPop from '../../../modules/apps/masters/modal/ConsultantPop'
import AdpPopup from '../../../modules/apps/masters/modal/AdpPopup'
import ConsultantQBPop from '../../../modules/apps/masters/modal/ConsultantQBPop'
import AdpAssociatePopup from '../../../modules/apps/masters/modal/AdpAssociatePopup'
import validator from 'validator'
import DatePickerIcon from '../../../modules/apps/masters/commonicons/DatePickerIcon'
import {Toolbar1} from '../../../../_metronic/layout/components/toolbar/Toolbar1'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import AddDocument from '../../../pages/masters/manageConsultants/AddDocument'
import CorrespondenceDetails from '../../../pages/myConsultants/CorrespondenceDetails'
import ConsultantActiveInactivePop from '../../../modules/apps/masters/modal/ConsultantActiveInactivePop'
type UserSubmitForm = {
  joining_date: null
  work_country: string
  profileIdResponse: any
  leaving_date: string
  timesheet_approver: string
  searchcandidate: string
  qb_employee_name: string
  netSuite_employee_name: string
  work_city: string
  prospective_user_id: string
  visa_status: string
  qb_employee_id: number
  netSuite_employee_id: number
  prefix: string
  gender: string
  record_type_status: string
  first_name: string
  name: string
  phone_number: string
  phone: string
  maxLength: any
  last_name: string
  display_name: string
  company_code: string
  file_number: string
  user_status: string
  employeeId: any
  toolName: any
  orgId: any
  domain: string
  email_notifications: string
  business_unit_id: number
  relation_manager: number
  sales_contact: number
  branch_id: number
  department_id: number
  approver_ids: string
  roleName: any
  org_name: any
  userStatus: any
  ecdb_user_id: number
  role: string
  email: string
  firstName: string
  full_name: string
  employee_type: string
  search_candidate: string
  adp_associate_id: string
  status: string
  userId: any
  id: any
  employee_id: any
  candidateId: any
  org_id: any
  profileResponse: any
  work_state: string
  zipcode: string
  permanent_address: string
  client_manager_name: string
  work_location: string
  work_address: string
  work_street_address: string
}

const AddConsultant = (props: any) => {
  const methods = useForm()
  const intl = useIntl()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)
  const [Edit, setEdit] = useState(false)
  const [isLogin, setIsLogin] = useState('')
  const [canFlag, setCanFlag] = useState(true)
  const [AdpValidateFieldsDisabled, setAdpValidateFieldsDisabled] = useState(false)
  const [JoiningFieldsDisabled, setJoiningFieldsDisabled] = useState(false)
  const [AllData, setAllData] = useState<any>()
  const [EmployeeIdData, setEmployeeIdData] = useState<any>()
  const [visaFlag, setVisaFlag] = useState(false)
  const [adpDisableddData, setadpDisabledData] = useState(false)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [DisableNetSuite, setDisableNetSuite] = useState(false)
  const [SubsidaryName, setSubsidaryName] = useState<any>()
  const [adpData, setAdpData] = useState('')
  const [ValidateAdpByConsultantName, setValidateAdpByConsultantName] = useState(false)
  const [key, setKey] = useState<any>('personal')
  const [AdpConsultantname, setAdpConsultantname] = useState('')
  const [ProjectDetails, setProjectDetails] = useState<any | []>([])
  const [SalesDetails, setSalesDetails] = useState<any | []>([])
  const [disableAdp, setDisableAdp] = useState(false)
  const [disableEmployeeLevelAdp, setDisableEmployeeLevelAdp] = useState(false)
  const [clientname, setClientName] = useState<any | []>([])
  const [userUpdateId, setUserUpdateId] = useState<any | []>([])
  const [userEcdbId, setUserEcdbId] = useState<any | []>([])
  const [userNameId, setUserNameId] = useState<any | []>([])
  const [profileResponseId, setProfileResponseId] = useState<any | []>([])
  const [profileResponseLocalId, setProfileResponseLocalId] = useState<any | []>([])
  const [dataIdprofile, setDataidProfile] = useState<any | []>([])
  const [isView, setIsView] = useState(false)
  const [singleSelections, setSingleSelections] = useState<any[]>([])
  const [JoiningDate, setJoiningDate] = useState(null)
  const [LeavingDate, setLeavingDate] = useState(null)
  const [adpFirstName, setAdpFirstName] = useState('')
  const [adpMessage, setAdpMessage] = useState(false)
  const [adpLastName, setAdpLastName] = useState('')
  const [adpStatus, setAdpStatus] = useState('')
  const [adpDate, setAdpDate] = useState('')
  const [checkAdpData, setCheckAdpData] = useState<any[]>([])
  const [userIdStore, setUserIdStore] = useState<any[]>([])
  const [prospectiveLocalIdData, setProspectiveLocalIdData] = useState<any[]>([])
  const [userIdData, setUserIdData] = useState<any[]>([])
  const [responseData, setResponseData] = useState('')
  const [responseData1, setResponseData1] = useState('')
  const [employeeTypeData, setEmployeeTypeData] = useState<any>()
  const [ecdbUserId, setEcdbUserId] = useState<any[]>([])
  const [transactionDisabled, setTransactionDisabled] = useState<any[]>([])
  const [IndividualBU, setIndividualBU] = useState(false)
  const [candidate, setCandidate] = useState<any>({})
  const [clientopen, setClientOpen] = useState(false)
  const [netopen, setNetOpen] = useState(false)
  const [ProspectiveFieldDisable, setProspectiveFieldDisable] = useState(true)
  const [insertDisable, setInsertDisable] = useState(false)
  const [users, setUsers] = useState([])
  const [numberData, setNumberData] = useState('')
  const [nameData, setNameData] = useState('')
  const [visaStatus, setVisaStatus] = useState('')
  const [ecdbData, setECDBData] = useState('')
  const [countryData, setCountryData] = useState('')
  const [lastData, setLastData] = useState('')
  const [adpId, setADPID] = useState('')
  const [zip, setZIP] = useState('')
  const [country, setCountry] = useState<any | []>([])
  const [OldObj, setOldObj] = useState<any>({})
  const [stateName, setStateName] = useState<any | []>([])
  const [workAuthorizationData, setWorkAuthorizationData] = useState<any | []>([])
  const [nameEmployee, setNameEmployee] = useState<any | []>([])
  const [title, setTitle] = useState<any | []>([])
  const [checkedEmail, setCheckedEmail] = useState<any | []>([])
  const [ecdbUserIdData, setEcdbUserIdData] = useState<any>()
  const [profileResponseId1, setProfileResponseId1] = useState<any | []>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [text, setText] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [disabled, setDisable] = useState(true)
  const [loading, setLoading] = useState(true)
  const [qb_disable_fields, setqbDisableFields] = useState(false)
  const [message, setMessage] = useState(false)
  const [resProspDetailsFromLocaldb, setResProspDetailsFromLocaldb] = useState(false)
  const [visaDisabled, setVisaDisabled] = useState(true)
  const [resEcdbDetailsFromLocaldb, setResEcdbDetailsFromLocaldb] = useState(false)
  const [searchCandidateDisable, setSearchCandidateDisable] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [visaStatusData, setVisaStatusData] = useState<any | []>([])
  const [statesId, setStatesId] = useState<any | []>([])
  const location = useLocation()
  const [AdpAssociateIdDataMessage, setAdpAssociateIdDataMessage] = useState(false)
  const [weekDayDataEntry, setweekDayDataEntry] = useState<any[]>([])
  const [monthDayDataEntry, setMonthDayDataEntry] = useState<any[]>([])
  const [userId, setUserId] = useState([])
  const [prospUpdateId, setProspUpdateId] = useState()
  const [ECDBUpdateId, setECDBUpdateId] = useState<any>()
  const [userIdData11, setUserDataId] = useState<any>()
  const [mainTableData, setMainTableData] = useState([])
  const [updateTableData, setUpdateTableData] = useState([])
  const [emailError, setEmailError] = useState('')
  const [viewshow, setViewShow] = useState(false)
  const [viewshow1, setViewShow1] = useState(false)
  const [buttonAdpDisable, setButtonAdpDisable] = useState(true)
  const [buttonAdpUpdateDisable, setButtonAdpUpdateDisable] = useState(false)
  const [AdpCheckbox, setAdpCheckbox] = useState(false)
  const [buttonAdpDisableUpdate, setButtonAdpDisableUpdate] = useState(false)
  const [validateButton, setValidateButton] = useState(false)
  const [ActiveInactive, setActiveInactive] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')
  const [workData1, setWorkData1] = useState('')
  const [workId, setWorkId] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<any | []>([])
  const [list, setList] = useState(false)
  const [customermessage, setCustomerMessage] = useState('')
  const [consultantstatus, setConsultantStatus] = useState('')
  const [propsConsultant, setPropsConsultant] = useState('')
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BranchList, setBranchList] = useState<any | []>([])
  const [AllBranchesList, setAllBranchesList] = useState<any | []>([])
  const [ApproverBusinessList, setApproverBusinessList] = useState<any | []>([])
  const [SelectedApproverBusinessList, setSelectedApproverBusinessList] = useState<any>()
  const [AllDepartmentsList, setAllDepartmentsList] = useState<any | []>([])
  const [InternalUserDetails, setInternalUserDetails] = useState<any | []>([])
  const [RelationDetails, setRelationDetails] = useState<any | []>([])
  const [AllRelationManagerList, setAllRelationManagerList] = useState<any | []>([])
  const [SelectedRelationList, setSelectedRelationList] = useState<any>()
  const [AllSaleContactList, setAllSaleContactList] = useState<any | []>([])
  const [DepartmentList, setDepartmentList] = useState<any | []>([])
  const [ApproverUnitsList, setApproverUnitsList] = useState<any | []>([])
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const [ViewEdit, setViewEdit] = useState(false)
  const [selectConsultant, setSelectConsultant] = useState<any>([])
  const [Relation, setRelation] = useState<any>([])
  const [disableQBClient, setDisableQBClient] = useState(false)
  const [QBConsultant, setQBConsultant] = useState(false)
  const [sale, setSale] = useState<any>([])
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
    display_name: Yup.string().required('Display Name is required').nullable(),
    zipcode: Yup.string().required('Zip Code  is required').nullable(),
    work_city: Yup.string().required('Work City  is required').nullable(),
    last_name: Yup.string().required('Last Name is required').nullable(),
    email: Yup.string().required('Email Id  is required').nullable(),
    joining_date: Yup.string().required('Date Of Joining is required').nullable(),
    gender: Yup.string().required('Gender is required').nullable(),

    leaving_date: Yup.string().when('record_type_status', {
      is: 'Inactive' || 'InActive',
      // then: Yup.string().required('Must enter email address'),
      then: (schema) => schema.required('Leaving Date is required').nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    // adp_associate_id:  Yup.string().required('Associate Id is required').nullable(),
    // visa_status: Yup.string().when('employee_type', {
    //   is: 'W2' || 'Internal',
    //   then: (schema) => schema.required('Visa Status is required').nullable(),
    //   otherwise: (schema) => schema.notRequired().nullable(),
    // }),

    visa_status: Yup.string().when('employee_type', {
      is: (employee_type: any) => employee_type == 'W2' || employee_type == 'Internal',
      then: (schema) => schema.required('Visa Status is required').nullable(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),

    // prospective_user_id: Yup.string().when('employee_type', {
    //   is: (employee_type: any) => employee_type == 'Internal',
    //   then: (schema) => schema.notRequired().nullable(),
    //   otherwise: (schema) => schema.required('Prospective ID is required').nullable(),
    // }),

    // adp_associate_id: Yup.string().when('employee_type', {
    //   is: 'W2' && 'disableAdp',
    //   // then: Yup.string().required('Must enter email address'),
    //   then: (schema) => schema.required('ADP Associate ID is required').nullable(),
    //   otherwise: (schema) => schema.notRequired().nullable(),
    // }),
    business_unit_id: Yup.string().required('Business Unit is required').nullable(),
    // branch_id: Yup.string().required('Branch is required').nullable(),
    department_id: Yup.string().required('Department is required').nullable(),

    prefix: Yup.string().required('Prefix  is required').nullable(),
    employee_type: Yup.string().required('Consultant Type  is required').nullable(),
    phone_number: Yup.string().required('Phone Number  is required').nullable(),
  })

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }

  const handleClick33 = () => {
    window.location.href = '/timetracker/approver'
  }

  const handleConsultantType = (consultant: any) => {
    setValue('first_name', '')
    setValue('last_name', '')
    setValue('phone_number', '')
    setValue('email', '')
    setValue('employee_id', '')
    setValue('display_name', '')
    setValue('prefix', '')
    setValue('gender', '')
    setValue('prospective_user_id', '')
    setSelectedItem(consultant)
    if (consultant == 'Internal') {
      setCanFlag(false)
    } else {
      setCanFlag(true)
    }
  }
  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  const onClickSubmit = async (name: any) => {
    var email = name
    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Enter valid Email!')
    }
    if (selectedItem !== 'Internal') {
      // return false
      const response: any = await Apiservices.getemployeebyemail({email: name})

      if (response?.data?.message == 'Email Already Exists') {
        ErrorToast('Email exist in Time Tracker')
        setMessage(true)
      } else {
        setMessage(false)
      }

      let organizationId = localStorage.getItem('logged_in_id')
      // const res = await axios.get<any>(
      //   `https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId/${organizationId}?name=${name}`
      // )

      const res: any = await Apiservices.getUpdateSearchCandidate({
        // name: name,
        keyword: name,
      })
      // if (res.data.profileResponse.length > 0) {
      if (res.data.data.length > 0) {
        const resProspDetailsFromLocal: any = await Apiservices.prosLocalEmail({
          // prospective_user_id: res?.data?.profileResponse[0]?.id,
          prospective_user_id: res?.data?.data[0]?.id,
        })
        // setProfileResponseId(res?.data?.profileResponse[0]?.id)
        // setDataidProfile(res?.data?.profileResponse)
        // setProfileResponseId1(res.data.profileResponse[0]?.id)
        setProfileResponseId(res?.data?.data[0]?.id)
        setDataidProfile(res?.data?.data)
        setProfileResponseId1(res.data.data[0]?.id)
        if (resProspDetailsFromLocal.data.message == 'Records Found') {
          ErrorToast('Prospective Id Exist in Time Tracker')
          setResProspDetailsFromLocaldb(true)
        } else {
          setResProspDetailsFromLocaldb(false)
        }
      } else {
        setResProspDetailsFromLocaldb(false)
      }

      let id = localStorage.getItem('logged_in_id')
      const employeeValid: any = await Apiservices.validateEmployee1({orgId: id, email: name})

      if (employeeValid.data.message == 'Already Exists') {
        let checkedEmailData = {
          email: name,
          orgId: id,
        }
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)

        let ecdbUserIdData = employeeValid1.userId
        if (employeeValid1.message == 'Email Already Exist') {
          // setResEcdbDetailsFromLocaldb(true)
          const resECDBDetailsFromLocal: any = await Apiservices.ECDBLocalEmail({
            ecdb_user_id: ecdbUserIdData,
          })
          if (resECDBDetailsFromLocal.data.message == 'Records Found') {
            ErrorToast('ECDB Id Exist in Time Tracker')
            setResEcdbDetailsFromLocaldb(true)
          } else {
            setResEcdbDetailsFromLocaldb(false)
          }
        }
      } else {
        setResEcdbDetailsFromLocaldb(false)
      }
      // setProfileResponseId(res?.data?.profileResponse[0]?.id)
      // setDataidProfile(res?.data?.profileResponse)
      // setProfileResponseId1(res.data.profileResponse[0]?.id)
    } else {
      const response: any = await Apiservices.getemployeebyemail({email: name})
      if (response?.data?.message == 'Email Already Exists') {
        ErrorToast('Email exist in Time Tracker')
        setMessage(true)
      } else {
        setMessage(false)
      }
      let id = localStorage.getItem('logged_in_id')
      const employeeValid: any = await Apiservices.validateEmployee1({orgId: id, email: name})

      if (employeeValid.data.message == 'Already Exists') {
        let checkedEmailData = {
          email: name,
          orgId: id,
        }
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)
        let ecdbUserIdData = employeeValid1.userId
        setEcdbUserIdData(ecdbUserIdData)
        if (employeeValid1.message == 'Email Already Exist') {
          setResEcdbDetailsFromLocaldb(true)
          const resECDBDetailsFromLocal: any = await Apiservices.ECDBLocalEmail({
            ecdb_user_id: ecdbUserIdData,
          })
          if (resECDBDetailsFromLocal.data.message == 'Records Found') {
            ErrorToast('ECDB Id Exist in Time Tracker')
            setResEcdbDetailsFromLocaldb(true)
          } else {
            setResEcdbDetailsFromLocaldb(false)
          }
        }
      } else {
        setResEcdbDetailsFromLocaldb(false)
        setEcdbUserIdData(null)
      }
    }
  }

  const onClickUpdate = async (name: any) => {
    if (employeeTypeData !== 'Internal') {
      let organizationId = localStorage.getItem('logged_in_id')
      const response: any = await Apiservices.getemployeebyemailId({email: name, id: userId})

      // const res = await axios.get<any>(
      //   `https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId/${organizationId}?name=${name}`
      // )
      const res: any = await Apiservices.getUpdateSearchCandidate({
        // name: name,
        keyword: name,
      })

      // if (res.data.profileResponse.length > 0) {
      if (res.data.data.length > 0) {
        const resProspDetailsFromLocal: any = await Apiservices.prosLocalEmailUpdate({
          // prospective_user_id: res?.data?.profileResponse[0]?.id,
          prospective_user_id: res?.data?.data[0]?.id,

          id: userId,
        })
        if (resProspDetailsFromLocal.data.message == 'Records Found') {
          ErrorToast('Prospective Id Exist in Time Tracker')
          setResProspDetailsFromLocaldb(true)
        } else {
          setResProspDetailsFromLocaldb(false)
        }
      } else {
        setResProspDetailsFromLocaldb(false)
      }
      let id = localStorage.getItem('logged_in_id')
      const employeeValid: any = await Apiservices.validateEmployee1({orgId: id, email: name})

      if (employeeValid.data.message == 'Already Exists') {
        let checkedEmailData = {
          email: name,
          orgId: id,
        }
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)
        let ecdbUserIdData = employeeValid1.userId
        if (employeeValid1.message == 'Email Already Exist') {
          const resECDBDetailsFromLocal: any = await Apiservices.ECDBLocalUpdateEmail({
            ecdb_user_id: ecdbUserIdData,
            id: userId,
          })
          if (resECDBDetailsFromLocal.data.message == 'Records Found') {
            setResEcdbDetailsFromLocaldb(true)
            ErrorToast('ECDB Id Exist in Time Tracker')
          }
        }
      } else {
        setResEcdbDetailsFromLocaldb(false)
      }
      // setProfileResponseId(res?.data?.profileResponse[0]?.id)
      // setProfileResponseId(res?.data?.profileResponse[0]?.id)
      // setProfileResponseId(res?.data?.profileResponse[0]?.id)
      // setProfileResponseLocalId(res?.data?.profileResponse[0]?.id)
      // setDataidProfile(res.data.profileResponse)

      setProfileResponseId(res?.data?.data[0]?.id)
      setProfileResponseId(res?.data?.data[0]?.id)
      setProfileResponseId(res?.data?.data[0]?.id)
      setProfileResponseLocalId(res?.data?.data[0]?.id)
      setDataidProfile(res.data.data)

      if (response.data.message == 'Email Already Exists') {
        ErrorToast('Email Already Exists')
        setMessage(true)
      } else {
        setMessage(false)
      }
    } else {
      let id = localStorage.getItem('logged_in_id')
      const response: any = await Apiservices.getemployeebyemailId({email: name, id: userId})

      const employeeValid: any = await Apiservices.validateEmployee1({orgId: id, email: name})
      if (employeeValid.data.message == 'Already Exists') {
        let checkedEmailData = {
          email: name,
          orgId: id,
        }
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)

        let ecdbUserIdData = employeeValid1.userId
        setCheckedEmail(employeeValid1.userId)
        if (employeeValid1.message == 'Email Already Exist') {
          const resECDBDetailsFromLocal: any = await Apiservices.ECDBLocalUpdateEmail({
            ecdb_user_id: ecdbUserIdData,
            id: userId,
          })
          if (resECDBDetailsFromLocal.data.message == 'Records Found') {
            setResEcdbDetailsFromLocaldb(true)
            ErrorToast('ECDB Id Exist in Time Tracker')
          }
        }
      } else {
        setResEcdbDetailsFromLocaldb(false)
        setEcdbUserIdData(null)
      }
      if (response.data.message == 'Email Already Exists') {
        ErrorToast('Email Already Exists')
        setMessage(true)
      } else {
        setMessage(false)
      }
    }
  }

  const userName = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''
      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }
  const validateEmail = (e: any) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('Valid Email :)')
    } else {
      setEmailError('Enter valid Email!')
    }
  }
  const ConsultantQBOk = (val: any) => {
    handleCustomerName('', val)
  }
  const ConsultantQBClose = (val: any) => {
    setQBConsultant(false)
  }
  const handleCustomerName = async (client_name: any, fromVal: any) => {
    setQBConsultant(true)
    if (fromVal == 'OK') {
      let data5: any = {
        GivenName: candidate.first_name,
        FamilyName: candidate.last_name,
        // DisplayName: candidate.full_name,
        DisplayName: getValues('qb_employee_name'),
      }
      setShowBackDrop(true)
      const res: any = await Apiservices.getcustomerdatabyname1(candidate.business_unit_id, data5)
      setQBConsultant(false)
      setShowBackDrop(false)
      if (res.isError == false) {
        if (res.message == 'No Records Found') {
          setDisableQBClient(false)
          ErrorToast('Please check if the consultant is available in QB.')
        } else {
          setDisableQBClient(true)
          setClientOpen(true)
          SuccessToast('Consultant ID fetched Successfully')
          setValue('qb_employee_id', res.data.QueryResponse.Employee[0].Id)
        }
      } else if (res.isError == true) {
        ErrorToast('No Records Found for QB Consultant')
      }
    }
  }
  const handleCustomerName1 = async (client_name: any) => {
    let AllDepts = DepartmentList.filter(
      (test: any) => test.ecdb_department_id == getValues('department_id')
    )
    let AllBus = BusinessUnitsList.filter(
      (test: any) => test.business_unit_id == getValues('business_unit_id')
    )

    let data5: any = {
      employee_type: selectedItem,
      first_name: candidate.first_name,
      middle_name: candidate.middle_name,
      last_name: candidate.last_name,
      subsidiaryName: localStorage.getItem('subsidary_name'),
      email: getValues('email'),
      phone_number: getValues('phone_number'),
      department_name: AllDepts && AllDepts[0]?.name,
      // department_name: 'HR',
      country_name: countries[0].name,
      city: getValues('work_city'),
      state_name: statesId[0].name,
      joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
      zipcode: getValues('zipcode'),
      employee_id: getValues('employee_id'),
      isjobresource: true,
      classificationName: AllBus && AllBus[0]?.name,
      // classificationName: 'BU1',
    }

    setShowBackDrop(true)
    const res: any = await Apiservices.getEmployee(data5)
    setShowBackDrop(false)
    if (res.statusCode == 200) {
      setNetOpen(true)
      SuccessToast('NetSuite ID fetched Successfully')
      setValue('netSuite_employee_id', res.data.netsuiteId)
    } else {
      ErrorToast('No Records Found for QB Customer name')
    }
  }
  const getAdpValidateUpdate = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    setDisableAdp(res?.data?.data[0]?.enable_adp)
    setDisableEmployeeLevelAdp(res?.data?.data[0]?.validate_adp_employee)
    setDisableQuickBooks(res?.data?.data[0]?.enable_quickbooks)
    setDisableNetSuite(res?.data?.data[0]?.enable_netsuite)
    setSubsidaryName(res?.data?.data[0]?.subsidary_name)
    localStorage.setItem(
      'enable_quickbooks',
      res && res.data && res.data.data && res.data.data[0] && res.data.data[0].enable_quickbooks
    )
    localStorage.setItem(
      'enable_netsuite',
      res && res.data && res.data.data && res.data.data[0] && res.data.data[0].enable_netsuite
    )
    localStorage.setItem(
      'subsidary_name',
      res && res.data && res.data.data && res.data.data[0] && res.data.data[0].subsidary_name
    )
  }
  useEffect(() => {
    getAdpValidateUpdate()
    get_business_units_list()
    get_branches_list()
    get_departments_list()
    get_share_user()
    // get_relation_list()
  }, [])

  const get_share_user = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.shareUser({orgId: get_org_id})
    if (res && res.data && res.data.statusCode == 200) {
      setInternalUserDetails(res.data.internalUserDetails)
    }
  }
  const get_relation_list = async () => {
    const response: any = await Apiservices.get_relation_managers_list()
    if (response && response.data && response.data.isError == false) {
      setRelationDetails(response.data.data)
    }
  }
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      setBusinessUnitsList(res.data.data)
    }
  }
  const get_branches_list = async () => {
    const res: any = await Apiservices.get_branches_list()
    if (res && res.status == 200) {
      setBranchList(res.data.data)
    }
  }
  const get_departments_list = async () => {
    const res: any = await Apiservices.get_departments_list()
    if (res && res.status == 200) {
      setDepartmentList(res.data.data)
    }
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    renderThings()
  }, [])

  useEffect(() => {
    let state: any = location.state || props.state
    setViewEdit(state?.comments_tab)
    console.log(location.state, 'props')
    if (numArray?.includes('relationshipmanager') && state?.commentHistory == false) {
      setKey('comments')
    }
    let userIdStore = location.state
    const viewEmployee = async (id: any) => {
      setInsertDisable(true)
      setUserId(id)
      getUserIdDataBasedOnId(id)
    }
    if (location.state || props.state) {
      setEdit(true)
      setIsEdit(true)
      setIsLogin(state.login)
      setProspectiveFieldDisable(state.view ? true : false)
      setIsView(state.view)
      setCanFlag(state.view ? true : false)
      viewEmployee(state.id || props.state)
      setqbDisableFields(true)
    }
  }, [])
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const getUserIdDataBasedOnId = async (passedId: any) => {
    setVisaDisabled(false)
    setUserUpdateId(passedId)
    setShowBackDrop(true)
    // const resData: any = await Apiservices.qbTransastion({name: 'getemployeedatabyname'})
    // setTransactionDisabled(resData?.data?.data?.[0]?.is_enabled)

    const response: any = await Apiservices.getemployeebyid({id: passedId}) //user2/getuserbyid

    const resData: any = await Apiservices.qbTransastion({
      name: 'getemployeedatabyname',
      business_unit_id: response.data.data.business_unit_id,
    })
    setTransactionDisabled(resData?.data?.data?.[0]?.is_enabled)
    setNameData(response.data.data.first_name)
    setVisaStatus(response.data.data.visa_status)
    setCheckAdpData(response.data.data.adp_associate_id)
    setECDBData(response.data.data.ecdb_user_id)
    setCountryData(response.data.data.work_country)
    setLastData(response.data.data.last_name)
    setNumberData(response.data.data.phone_number)
    setNumberData(response.data.data.phone)
    setEmployeeTypeData(response.data.data.employee_type)
    setSelectedItem(response.data.data.employee_type)
    setValue('employee_type', response.data.data.employee_type)
    setProspectiveLocalIdData(response?.data?.data?.prospective_user_id)
    setProspUpdateId(response?.data?.data?.prospective_user_id)
    setECDBUpdateId(response.data.data.ecdb_user_id)
    setUserDataId(response.data.data.id)
    const business1: any = await Apiservices.get_business_units_list()
    if (business1 && business1.status == 200) {
      if (response.data.data.business_unit_id) {
        let check_qb = business1?.data?.data.filter(
          (bu: any) => bu.business_unit_id == response?.data?.data?.business_unit_id
        )
        if (check_qb?.length > 0) {
          setIndividualBU(check_qb && check_qb[0] && check_qb[0].enable_quickbooks)
        }
      }
      if (response?.data?.data?.business_unit_id) {
        let businessValueValidating = business1?.data?.data.filter(
          (bus: any) => bus.business_unit_id == response?.data?.data?.business_unit_id
        )
        if (businessValueValidating.length == 0) {
          setValue('business_unit_id', response?.data?.data?.business_unit_id)

          let abc1 = {
            business_unit_id: response?.data?.data?.business_unit_id,
            name: response?.data?.data?.business_unit_name,
          }
          let abc = [...BusinessUnitsList, abc1]
          setBusinessUnitsList(abc)
        }
      }
    }

    let data5: any = {
      employee_type: response?.data?.data?.employee_type,
      first_name: response?.data?.data?.first_name,
      middle_name: response?.data?.data?.middle_name,
      last_name: response?.data?.data?.last_name,
      subsidiaryName: localStorage.getItem('subsidary_name'),
      email: response?.data?.data?.email,
      phone_number: response?.data?.data?.phone_number,
      // department_name: AllDepts && AllDepts[0]?.name,
      department_name: response?.data?.data?.department_name,
      country_name: response?.data?.data?.work_country,
      city: response?.data?.data?.work_city,
      state_name: response?.data?.data?.work_state,
      joining_date: response?.data?.data?.joining_date,
      zipcode: response?.data?.data?.zipcode,
      employee_id: response?.data?.data?.employee_id,
      isjobresource: true,
      // classificationName: AllBus && AllBus[0]?.name,
      classificationName: response?.data?.data?.business_unit_name,
    }
    setOldObj(data5)
    setValue('branch_id', response?.data?.data?.branch_id)
    setValue('department_id', response?.data?.data?.department_id)
    if (response?.data?.data?.business_unit_id) {
      const res: any = await Apiservices.get_approvers_list_by_business_unit_id({
        business_unit_id: response?.data?.data?.business_unit_id,
      })
      if (res && res.status == 200) {
        if (res?.data?.data) {
          setApproverUnitsList(res.data.data)
        }
      }
      const res1: any = await Apiservices.get_relation_managers_list({
        business_unit_id: response?.data?.data?.business_unit_id,
      })
      if (res1 && res1.data && res1.data.isError == false) {
        if (res1?.data?.data) {
          setRelationDetails(res1.data.data)
        }
      }
    }
    setSelectConsultant(response?.data?.data?.approvers)
    setRelation(response?.data?.data?.relation_managers)
    let workCountry: any = []
    for (let p = 0; p < response?.data?.data?.sales_contacts?.length; p++) {
      workCountry.push({
        firstName: response?.data?.data?.sales_contacts
          ? response?.data?.data?.sales_contacts[p].firstname
          : '',
        lastName: response?.data?.data?.sales_contacts
          ? response?.data?.data?.sales_contacts[p].lastname
          : '',
        userId: response?.data?.data?.sales_contacts[p].userid,
      })
      setSale(workCountry)
    }

    setConsultantStatus(response?.data?.data?.record_type_status)
    // setValue('sales_contact', response?.data?.data?.sc_id)
    // setValue('relation_manager', response?.data?.data?.rm_id)
    setValue('record_type_status', response?.data?.data?.record_type_status)
    setValue('visa_status', response?.data?.data?.visa_status)
    setValue('work_country', response?.data?.data?.work_country)
    setValue(`work_street_address`, response.data.data.work_street_address)
    setValue(`work_address`, response.data.data.work_address)
    setValue(`permanent_address`, response.data.data.permanent_address)
    setValue('display_name', response?.data?.data?.display_name)
    setAdpAssociateIdDataMessage(response.data.data.adp_validated)
    // setValue('joining_date', response.data.data.joining_date)
    let start_date_format: any = new Date(response.data.data.joining_date)
    let start_date_format1 = moment(start_date_format).format('MM-DD-YYYY')
    let start_date_format2: any = new Date(
      moment(response.data.data.joining_date, 'YYYY-MM-DD').format('MM-DD-YYYY')
    )
    if (start_date_format1 == '01-01-1970') {
      setJoiningDate(null)
      setJoiningFieldsDisabled(false)
    } else {
      setJoiningDate(start_date_format2)
      setJoiningFieldsDisabled(true)
    }
    let leave_date_format: any = new Date(response?.data?.data?.leaving_date)
    let leave_date_format1 = moment(leave_date_format).format('MM-DD-YYYY')
    if (leave_date_format1 == '01-01-1970') {
      setLeavingDate(null)
    } else {
      setLeavingDate(leave_date_format)
    }
    // setLeavingDate(leave_date_format)
    setCandidate(response.data.data)
    if (response.data.data.qb_employee_id) {
      setClientOpen(true)
    }

    if (response.data.data.netsuite_consultant_id) {
      setNetOpen(true)
    }

    let dataSet = {
      ...response.data.data,
      // qb_employee_name: response.data.data.full_name,
      qb_employee_name: response.data.data.qb_employee_name
        ? response.data.data.qb_employee_name
        : response.data.data.full_name
        ? response.data.data.full_name
        : '',
      netSuite_employee_name: response.data.data.full_name,
      netSuite_employee_id: response.data.data.netsuite_consultant_id,
      visa_status: response?.data?.data?.visa_status,
      // relation_manager: response?.data?.data?.rm_id,
      // sales_contact: response?.data?.data?.sc_id,
    }
    const dataName = [
      {
        name:
          response && response.data && response.data.data && response.data.data.work_country
            ? response.data.data.work_country
            : '',
      },
    ]
    // const dataName = [{name: response.data.data.work_country}]
    setCountries(dataName)
    const dataName1 = [
      {
        name:
          response && response.data && response.data.data && response.data.data.work_state
            ? response.data.data.work_state
            : '',
      },
    ]
    // const dataName1 = [{name: response.data.data.work_state}]
    setStatesId(dataName1)
    // ************* keeping down after all api's called *********** //
    // const response1: any = await Apiservices.projectUserData1({user_id: passedId}) //projects2/sync_projects_by_user_id
    // setMainTableData(response1.data.data)
    // const res: any = await Apiservices.updateUserEmpId({user_id: passedId}) //projects2/get_projects_by_user_id
    // setProjectDetails(res?.data?.data)

    // setMainTableData(res.data.data)
    // // setUpdateTableData(res.data.data[0].project_vendors)
    // setResponseData1(res.data.message)
    const response4: any = await Apiservices.updateVisaStatus() //https://prospects-uat.msrcosmos.com/prospective/country/listCountry
    const response5: any = await Apiservices.getallcountries() //getallcountries api
    reset(dataSet)
    if (response && response.data && response.data.data && response.data.data.work_country) {
      let stateName1 = response5?.data?.data.filter(
        (temp: any) => temp?.name == response?.data?.data?.work_country
      )
      if (stateName1) {
        setUseStateCountryId(stateName1[0].id)
        const response: any = await Apiservices.getstatebyname({country_id: stateName1[0].id})
        if (response?.data?.data) {
          setStateName(response && response.data && response.data.data ? response.data.data : '')
        } else {
          setStateName([])
        }
      }
    }
    let countryname = response4?.data?.countries
    let statename = response?.data?.data?.work_country
    if (statename) {
      let countid = countryname?.filter((temp: any) => temp?.countryName == statename)
      let visaStaeId = countid[0]?.countryId
      setVisaStatusData(countid[0]?.countryId)
      // const response2: any = await Apiservices.updateVisaStatusData({countryId: visaStaeId})
      if (visaStaeId) {
        const response2: any = await Apiservices.updateVisaStatusData(visaStaeId) //authorization/workAuthorization
        let input = response2?.data?.workAuthorization.filter(
          (each: any) => each?.workAuthorizationName == response?.data?.data?.visa_status
        )
        if (input) {
          setWorkId(input && input[0] && input[0].workAuthorizationId)
        }
        // setWorkId(response2?.data?.workAuthorization)

        setWorkAuthorizationData([
          {
            createdBy: null,
            createdOn: null,
            description: null,
            for1099: true,
            forC2C: true,
            forW2: true,
            rowStatus: false,
            status: 'ACTIVE',
            workAuthorizationId: '',
            workAuthorizationName: '',
          },
          ...response2?.data?.workAuthorization,
        ])
      }
      reset(dataSet)
    }
    setShowBackDrop(false)

    const response1: any = await Apiservices.projectUserData1({user_id: passedId}) //projects2/sync_projects_by_user_id
    setMainTableData(response1.data.data)
    const res: any = await Apiservices.updateUserEmpId({user_id: passedId}) //projects2/get_projects_by_user_id
    setProjectDetails(res?.data?.data)

    setMainTableData(res.data.data)
    // setUpdateTableData(res.data.data[0].project_vendors)
    setResponseData1(res.data.message)
  }

  const onchnagehandler1 = async (data: any) => {
    setShowBackDrop(true)
    console.log(data)
    setSingleSelections(data)
    let organizationId = localStorage.getItem('logged_in_id')
    let employee_id = data[0]?.id
    if (employee_id) {
      // const response = await axios.get<any>(
      //   `https://prospects-uat.msrcosmos.com/prospective/profile/search/${employee_id}?orgId=${organizationId}`
      // )
      const response: any = await Apiservices.getSearchCandidate(employee_id, {
        //prospects url (fetch all details)
        orgId: organizationId,
      })

      setProfileResponseId(data && data[0] && data[0]?.id) //fetching prospective id
      setProspectiveLocalIdData(data && data[0] && data[0]?.id) //fetching prospective id
      setCanFlag(false)
      setAdpData('exist')
      const resProspDetailsFromLocal: any = await Apiservices.prosLocalEmail({
        //verifying prospective id exist in our local tracker or not
        // prospective_user_id: profileResponseId, //passing prospective id
        prospective_user_id: employee_id, //passing prospective id
      })
      if (resProspDetailsFromLocal.data.message == 'Records Found') {
        ErrorToast('Prospective Id Exist in Time Tracker')
      }

      let email = response?.data?.email
      let id = localStorage.getItem('logged_in_id')
      if (email) {
        const employeeValid: any = await Apiservices.validateEmployee1({orgId: id, email: email}) //verify ECDB (userECDB/validateEmployeeExternal)
        if (employeeValid?.data?.message == 'Already Exists') {
          let checkedEmailData = {
            email: email,
            orgId: id,
          }
          const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData) //(list/checkEmail)verify email in ecdb

          // setSearchCandidateDisable(employeeValid1.message)
          let ecdbUserIdData = employeeValid1.userId //fetching ecdb id
          setEcdbUserIdData(ecdbUserIdData)
          if (employeeValid1.message == 'Email Already Exist') {
            // setSearchCandidateDisable(true)
            const resECDBDetailsFromLocal: any = await Apiservices.ECDBLocalEmail({
              //(user2/get_user_by_ecdb_user_id)
              ecdb_user_id: ecdbUserIdData, //verify data based on passing ecdb id in our local tracker
            })
            if (resECDBDetailsFromLocal.data.message == 'Records Found') {
              ErrorToast('ECDB Id Exist in Time Tracker')
              setResEcdbDetailsFromLocaldb(true)
            } else {
              setResEcdbDetailsFromLocaldb(false)
            }
          } else {
            setResEcdbDetailsFromLocaldb(false)
            setSearchCandidateDisable(false)
          }
        } else {
          setEcdbUserIdData(null)
          setResEcdbDetailsFromLocaldb(false)
        }
        const res: any = await Apiservices.getemployeebyemail({email: email}) //verifying email in our local tracker(user2/getuserbyemail)

        if (res?.data?.message == 'Email Already Exists') {
          ErrorToast('Email Already Exists')
        }
      } else {
        setEcdbUserIdData(null)
        setResEcdbDetailsFromLocaldb(false)
      }
      let prefixx = localStorage.getItem('org_prefix')
      setCandidate(response.data)
      setNameEmployee(response.data.name)
      setTitle(response.data.title)
      setValue('first_name', response.data.firstName)
      setValue('qb_employee_name', response.data.name)
      setValue('netSuite_employee_name', response.data.name)
      // setValue('joining_date', response.data.joiningDate)
      setValue('last_name', response.data.lastName)
      setValue('phone_number', response.data.phone)
      setValue('display_name', `${response.data.lastName} ${response.data.firstName}`)
      setValue('email', response.data.email)
      setValue('prospective_user_id', employee_id)
      if (response.data.gender == 1) {
        setValue('gender', 'MALE')
      }
      if (response.data.gender == 2) {
        setValue('gender', 'FEMALE')
      }
      // setValue('record_type_status', response.data.record_type_status)
      if (response.data.title == 3) {
        setValue('prefix', '2')
      }
      if (response.data.title == 1) {
        setValue('prefix', '1')
      }
      if (response.data.title == 2) {
        setValue('prefix', '3')
      }
      if (getValues('email')) {
        const consult: any = await Apiservices.getVerifyEmail({email: getValues('email')})
        if (consult?.data?.status == 'ALREADY_EXISTS') {
          setValue('employee_id', '')
        } else {
          setValue('employee_id', prefixx + '000' + Math.random().toString().slice(2, 5))
        }
      }
    }
    setShowBackDrop(false)
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      onChangeHandler(e.target.value, 'enter')
    }
  }
  const onChangeHandler = async (text: any, blur_event: any) => {
    setShowBackDrop(true)
    if (text == '') {
      setValue('first_name', '')
      setValue('last_name', '')
      setValue('phone_number', '')
      setValue('email', '')
      setValue('employee_id', '')
      setValue('display_name', '')
      setProfileResponseId([])
      setProspectiveLocalIdData([])
      setDataidProfile([])
      setSuggestions([])
    }
    setValue('search_candidate', text)
    // let matches: any = []
    // let spaced = '      '
    // let organizationId = localStorage.getItem('logged_in_id')
    // blur_event == 'Blur'
    if (blur_event == 'enter') {
      // if (text && text.length >= 3) {
      // const response = await axios.get<any>(
      //   `https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId/${organizationId}?name=${text}`

      const response: any = await Apiservices.getUpdateSearchCandidate({
        //prospects
        // name: text,
        keyword: text,
      })
      // setProfileResponseId(
      //   response.data.profileResponse &&
      //     response.data.profileResponse[0] &&
      //     response.data.profileResponse[0].id
      // ) //fetching prospective id
      // setProspectiveLocalIdData(
      //   response.data.profileResponse &&
      //     response.data.profileResponse[0] &&
      //     response.data.profileResponse[0].id
      // ) //fetching prospective id
      // setDataidProfile(response?.data?.profileResponse) //fetching prospective details
      // setSuggestions(response?.data?.profileResponse ? response?.data?.profileResponse : [])
      // typeaheadRef6.current.focus()
      // if (response.data.profileResponse?.length > 0) {
      //   setSuggestions(response?.data?.profileResponse ? response?.data?.profileResponse : [])
      // }

      setProfileResponseId(
        response?.data?.data && response.data.data[0] && response.data.data[0].id
      ) //fetching prospective id
      setProspectiveLocalIdData(
        response.data.data && response.data.data[0] && response.data.data[0].id
      ) //fetching prospective id
      setDataidProfile(response?.data?.data) //fetching prospective details
      setSuggestions(response?.data?.data ? response?.data?.data : [])
      typeaheadRef6.current.focus()
      if (response.data.data?.length > 0) {
        setSuggestions(response?.data?.data ? response?.data?.data : [])
      }
      // }
    }
    setShowBackDrop(false)
  }
  const handleCountries = async (country: any) => {
    if (country == '') {
      setValue('visa_status', '')
      setVisaDisabled(true)
      setStatesId([])
    }

    if (country && country.length >= 2) {
      const response: any = await Apiservices.getcountriesbyname({country_name: country})
      if (response.data.data !== null) {
        setCountry(response && response?.data && response?.data?.data ? response?.data?.data : '')
      }
    }
  }

  const selectedCountries = async (state: any) => {
    setVisaFlag(true)
    setCountries(state)
    setUseStateCountryId(state[0]?.id)
    let statename = state[0]?.name
    const response: any = await Apiservices.getstatebyname({country_id: state[0]?.id})
    if (response?.data?.data) {
      setStateName(response && response.data && response.data.data ? response.data.data : '')
    } else {
      setStateName([])
    }
    if (statename) {
      setVisaDisabled(false)

      const response1: any = await Apiservices.updateVisaStatus()
      let countryname = response1?.data?.countries
      let countid = countryname?.filter((temp: any) => temp?.countryName == statename)
      let visaStaeId = countid && countid[0] && countid[0].countryId
      // localStorage.setItem('countryId', visaStaeId)
      const response2: any = await Apiservices.updateVisaStatusData(visaStaeId)
      setWorkAuthorizationData([
        {
          createdBy: null,
          createdOn: null,
          description: null,
          for1099: true,
          forC2C: true,
          forW2: true,
          rowStatus: false,
          status: 'ACTIVE',
          workAuthorizationId: '',
          workAuthorizationName: '',
        },
        ...response2?.data?.workAuthorization,
      ])
    }
  }
  const handleProspectiveUserId = (prosp: any) => {
    let prospective: any = Number(prosp)
    setProspectiveLocalIdData(prospective)
    setProspUpdateId(prospective)
  }
  const handleStates = async (stateName: any) => {
    const response: any = await Apiservices.getstatebyname({country_id: useStateCountryId})
    if (response?.data?.data) {
      setStateName(response && response.data && response.data.data ? response.data.data : '')
    } else {
      setStateName([])
    }
  }

  const selectedStates = async (state: any) => {
    setStatesId(state)
  }

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  var orgID = localStorage.getItem('logged_in_id')

  const onSubmit = async (data: UserSubmitForm) => {
    let AllBranches = BranchList?.filter((temp: any) => temp.ecdb_branch_id == data.branch_id)
    let AllDepartments = DepartmentList.filter(
      (test: any) => test.ecdb_department_id == data.department_id
    )
    // let AllRelationManager = InternalUserDetails.filter(
    //   (test: any) => test.userId == data.relation_manager
    // )
    // let AllSalesContact = InternalUserDetails.filter(
    //   (test: any) => test.userId == data.sales_contact
    // )
    setAllBranchesList(AllBranches)
    setAllDepartmentsList(AllDepartments)
    // setAllRelationManagerList(AllRelationManager)
    // setAllSaleContactList(AllSalesContact)
    setAllData(data)
    let projectDetails = ProjectDetails
    if (projectDetails?.length > 0) {
      var ProjDetailsArray = []
      for (let p = 0; p < projectDetails?.length; p++) {
        var projDetails: any = {
          id: projectDetails[p].id,
          // consultant_name: projectDetails[p].full_name,
          consultant_name: `${data.first_name} ${data.last_name}`,
          client_name: projectDetails[p]?.project_vendors?.[0]?.client_name
            ? projectDetails[p]?.project_vendors?.[0]?.client_name
            : projectDetails[p]?.end_customer_name,
        }
        ProjDetailsArray.push(projDetails)
        setweekDayDataEntry([...ProjDetailsArray])
      }
    }

    if (sale?.length > 0) {
      var SaleDetailsArray = []
      for (let p = 0; p < sale?.length; p++) {
        var projDetails1: any = {
          sc_id: sale[p].userId,
          sc_name: `${sale[p].firstName} ${sale[p].lastName}`,
          first_name: sale[p].firstName,
          last_name: sale[p].lastName,
        }
        SaleDetailsArray.push(projDetails1)
        setMonthDayDataEntry([...SaleDetailsArray])
      }
    }
    let employee = selectConsultant?.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee?.filter((temp: any) => `${temp}`).join(',')

    let RelationArray = Relation?.map((value: any) => {
      return Number(value.id)
    })
    let SelectedRelations = RelationArray?.filter((temp: any) => `${temp}`).join(',')
    setApproverBusinessList(employee)
    setSelectedApproverBusinessList(employee1)

    setAllRelationManagerList(RelationArray)
    setSelectedRelationList(SelectedRelations)

    setShowBackDrop(true)
    if (isEdit === false) {
      setShowBackDrop(true)
      const employeeValid: any = await Apiservices.employeeId()
      setShowBackDrop(false)
      var employeeIdData =
        employeeValid &&
        employeeValid.data &&
        employeeValid.data.data &&
        employeeValid.data.data.employee_id
    }
    setEmployeeIdData(employeeIdData)
    if (isEdit === false) {
      if (selectedItem !== 'Internal') {
        let organizationId = localStorage.getItem('logged_in_id')
        // const res = await axios.get<any>(
        //   `https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId/${organizationId}?name=${data.email}`
        // )
        setShowBackDrop(true)
        const res: any = await Apiservices.getUpdateSearchCandidate({
          // name: data.email, //fetch profile response
          keyword: data.email,
        })
        setShowBackDrop(false)
        // if (res?.data?.profileResponse?.length == 0) {  //changed url end point
        if (res?.data?.data?.length == 0) {
          var passPropsID: any = prospectiveLocalIdData
          // } else if (prospectiveLocalIdData !== res?.data?.profileResponse[0]?.id) {
        } else if (prospectiveLocalIdData !== res?.data?.data[0]?.id) {
          var passPropsID: any = prospectiveLocalIdData
        } else {
          var passPropsID: any = prospectiveLocalIdData
        }
        if (passPropsID) {
          let pospData1 = {
            candidateId: passPropsID,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            phone: data.phone_number,
          }
          setShowBackDrop(true)
          const employeeValid1: any = await Apiservices.updateUserPros(pospData1) //added in local prospective(updateCandidateHrms)
          if (employeeValid1 && employeeValid1.message == 'Candidate Updated Successfully') {
            setShowBackDrop(false)
            SuccessToast('Prospective DB Updated Successfully ')
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)
        }
        let id = localStorage.getItem('logged_in_id')
        let space = ' '
        let toolName = localStorage.getItem('tool_name')
        let orgName = localStorage.getItem('org_name')
        let email = data.email
        setShowBackDrop(true)
        const employeeValid: any = await Apiservices.validateEmployee({orgId: id, email: email}) //userECDB/validateEmployeeExternal api(verifying email exist in ecdb or not)
        setShowBackDrop(false)
        if (employeeValid?.data?.message == 'Already Exists') {
          let checkedEmailData = {
            email: data.email,
            orgId: id,
          }
          setShowBackDrop(true)
          const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)
          setShowBackDrop(false)

          setCheckedEmail(employeeValid1?.userId)
          if (employeeValid1 && employeeValid1.message == 'Email Already Exist') {
            var EcdbUpdateUser = {
              id: employeeValid1.userId,
              // id: ECDBUpdateId,
              // domain: 'web-timesheets-uat.azurewebsites.net',
              domain: null,
              email: data.email,
              employeeId: employeeIdData,
              firstName: data.first_name,
              displayName: data.display_name,
              // joiningDate: '11-01-2022',
              // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
              joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

              leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

              lastName: data.last_name,
              orgId: id,
              phone: data.phone_number,
              roleName: 'Employee',
              toolName: toolName,
              // userStatus: 'ACTIVE',
              userStatus:
                data.record_type_status == undefined
                  ? 'ACTIVE'
                  : data.record_type_status == 'Active'
                  ? 'ACTIVE'
                  : 'INACTIVE',
              middleName: null,
              phoneNumber: data.phone_number,
              orgName: orgName,
              // branchId: null,
              // branchName: null,
              // departmentId: null,
              // departmentName: null,
              countryId: null,
              countryName: countries[0].name,
              supervisorId: 0,
              supervisorName: null,
              // gender: '',
              gender: data.gender,
              companyCode: data.company_code,
              fileNumber: data.file_number,
              businessUnitId: Number(data.business_unit_id),
              branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
              branchName: AllBranches && AllBranches[0]?.name,
              departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
              departmentName: AllDepartments && AllDepartments[0]?.name,
              fullName: data.first_name + space + data.last_name,
              linkId: null,
              fullProfile: false,
              editProfile: false,
              teamProfile: false,
              otherFullProfile: false,
              otherEditProfile: false,
              organizationInformation: false,
              otherOrganizations: null,
              signatoryAuthority: false,
              role: null,
              contractsRole: false,
              candidateReferencedBy: null,
              prospectiveRefId: dataIdprofile.length > 0 ? profileResponseId : null,
              empType: data.employee_type,
              // workLocation: 'Andhra Pradesh',
              workLocation: null,
              stateName: statesId[0].name,
              workCity: data.work_city,
              workStreetAddress: data.work_street_address,
              zipcode: data.zipcode,
              adpAssociateId: data.adp_associate_id,
              workAuthorizationId: workId,
              // wokAuthorizationId: workId,
              workAuthorizationName: workData1,
              jobBoardAccess: false,
            }
            console.log(EcdbUpdateUser, 'hdutfgdfyyf')
            setShowBackDrop(true)
            const resp: any = await Apiservices.ECDB_UserSearch_URL(employeeValid1.userId, {}) //onboarding-uat.msrcosmos.com/api/ecdb/user/search
            if (resp && resp.status == 200) {
              if (resp?.data?.pwd) {
                const sendingTokenForMail: any = await Apiservices.sendingTokenForMail({
                  email: data.email,
                  applicationToolName: 'Timesheet Tracker',
                })
                if (sendingTokenForMail.statusCode == 200) {
                  toast.success('Reset link has sent to your mail please check')
                }
              }
              setShowBackDrop(false)
            }
            setShowBackDrop(false)
            setShowBackDrop(true)
            const response: any = await Apiservices.updateUser(EcdbUpdateUser)
            var ecdb_user_iddata4 = response.userId

            setUsers(response?.user_id)
            setEcdbUserId(response?.userId)
            if (response && response.message == 'User Updated Successfully') {
              SuccessToast('ECDB User Updated Successfully')
              setShowBackDrop(false)
            } else {
              setShowBackDrop(false)
              toast.error('Something Went Wrong')
            }
            setShowBackDrop(false)
            let insertEmployeeLocal = {
              employee_type: data.employee_type,
              prefix: data.prefix,
              first_name: data.first_name,
              display_name: data.display_name,
              middle_name: '',
              last_name: data.last_name,
              email: data.email,
              // gender: 'MALE',
              gender: data.gender,
              business_unit_id: Number(data.business_unit_id),
              branch_id: Number(data.branch_id),
              approver_ids: employee1 ? employee : null,
              department_id: Number(data.department_id),
              branch_name: AllBranches && AllBranches[0]?.name,
              department_name: AllDepartments && AllDepartments[0]?.name,
              // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
              // rm_name:
              //   AllRelationManager &&
              //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
              relation_managers: SelectedRelations ? RelationArray : null,
              // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
              // sc_name:
              //   AllSalesContact &&
              //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
              sales_contacts: SaleDetailsArray,
              company_code: data.company_code,
              file_number: data.file_number,
              adp_associate_id: data.adp_associate_id,
              phone_number: data.phone_number,
              employee_id: employeeIdData,
              ecdb_user_id: employeeValid1.userId,
              prospective_user_id: dataIdprofile.length > 0 ? profileResponseId : null,
              // joining_date: data.joining_date,
              // joining_date: JoiningDate,
              joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,

              // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
              leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
              qb_employee_name: null,
              qb_employee_id: data.qb_employee_id,
              netsuite_consultant_id: getValues('netSuite_employee_id')
                ? getValues('netSuite_employee_id')
                : null,
              // user_status: data.record_type_status,
              user_status:
                data.record_type_status == undefined
                  ? 'Active'
                  : data.record_type_status == 'Active'
                  ? 'Active'
                  : 'InActive',
              work_location: 'Hyderabad',
              work_country: countries[0].name,
              work_state: statesId[0].name,
              work_city: 'Hyderabad',
              // work_street_address: 'test',
              work_status: null,
              zipcode: '123456',
              // email_notifications: (data.email_notifications == undefined) || (data.email_notifications == true )? "true" : "false",
              email_notifications:
                data.email_notifications == undefined
                  ? true
                  : data.email_notifications == 'true'
                  ? 'true'
                  : 'false',
              push_notifications: true,
              permanent_address: data.permanent_address,
              work_street_address: data.work_street_address,
              visa_status: workData1,
              workAuthorizationId: workId,
              // wokAuthorizationId: workId,

              // user_status: "ACTIVE"
            }
            setShowBackDrop(true)
            const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal)
            var UpdateIdRes = insertEmployeeData?.data[0]?.id
            // setUserUpdateId(insertEmployeeData.data[0].id)
            setUserIdStore(insertEmployeeData?.data[0]?.id)
            setUserNameId(insertEmployeeData?.data[0]?.first_name)
            setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)
            getUserIdDataBasedOnId(insertEmployeeData?.data[0]?.id)
            setProspectiveFieldDisable(false)
            // setInsertDisable(insertEmployeeData.message)
            if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
              SuccessToast('Time Tracker Updated Successfully')
              setInsertDisable(true)
              setIsEdit(true)
              const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes})
              setMainTableData(res.data.data)
              setShowBackDrop(false)
            } else {
              setShowBackDrop(false)
              toast.error('Something Went Wrong')
            }
            setShowBackDrop(false)
          }
          setShowBackDrop(false)
        } else if (ecdbUserIdData !== null) {
          var EcdbUpdateUser = {
            // id: ecdbUserIdData,
            id: ECDBUpdateId,
            // domain: 'web-timesheets-uat.azurewebsites.net',
            domain: null,
            email: data.email,
            employeeId: employeeIdData,
            firstName: data.first_name,
            displayName: data.display_name,
            // joiningDate: '11-01-2022',
            // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            lastName: data.last_name,
            orgId: id,
            phone: data.phone_number,
            roleName: 'Employee',
            toolName: toolName,
            // userStatus: 'ACTIVE',
            userStatus:
              data.record_type_status == undefined
                ? 'ACTIVE'
                : data.record_type_status == 'Active'
                ? 'ACTIVE'
                : 'INACTIVE',
            middleName: null,
            phoneNumber: data.phone_number,
            orgName: orgName,
            // branchId: null,
            // branchName: null,
            // departmentId: null,
            // departmentName: null,
            countryId: null,
            countryName: countries[0].name,
            supervisorId: 0,
            supervisorName: null,
            // gender: '',
            gender: data.gender,
            companyCode: data.company_code,
            fileNumber: data.file_number,
            businessUnitId: Number(data.business_unit_id),
            branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
            branchName: AllBranches && AllBranches[0]?.name,
            departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
            departmentName: AllDepartments && AllDepartments[0]?.name,

            fullName: data.first_name + space + data.last_name,
            linkId: null,
            fullProfile: false,
            editProfile: false,
            teamProfile: false,
            otherFullProfile: false,
            otherEditProfile: false,
            organizationInformation: false,
            otherOrganizations: null,
            signatoryAuthority: false,
            role: null,
            contractsRole: false,
            candidateReferencedBy: null,
            prospectiveRefId: dataIdprofile.length > 0 ? profileResponseId : null,
            empType: data.employee_type,
            // workLocation: 'Andhra Pradesh',
            workLocation: null,
            stateName: statesId[0].name,
            workCity: data.work_city,
            workStreetAddress: data.work_street_address,
            zipcode: data.zipcode,
            adpAssociateId: data.adp_associate_id,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
            workAuthorizationName: workData1,
            jobBoardAccess: false,
          }
          console.log(EcdbUpdateUser, 'hdutfgdfyyf')
          if (data.first_name !== nameData) {
            setShowBackDrop(true)
            const resp: any = await Apiservices.ECDB_UserSearch_URL(ecdbUserIdData, {})
            if (resp && resp.status == 200) {
              if (resp?.data?.pwd) {
                const sendingTokenForMail: any = await Apiservices.sendingTokenForMail({
                  email: data.email,
                  applicationToolName: 'Timesheet Tracker',
                })
                if (sendingTokenForMail.statusCode == 200) {
                  toast.success('Reset link has sent to your mail please check')
                }
              }
              setShowBackDrop(false)
            }
            setShowBackDrop(false)
            setShowBackDrop(true)
            const response: any = await Apiservices.updateUser(EcdbUpdateUser)

            setUsers(response?.user_id)
            var IdDaaadss = response.user_id
            setEcdbUserId(response?.userId)
            if (response && response.message == 'User Updated Successfully') {
              SuccessToast(' ECDB User Updated Successfully')
              setShowBackDrop(false)
            } else {
              setShowBackDrop(false)
              toast.error('Something Went Wrong')
            }
            setShowBackDrop(false)
          }

          let insertEmployeeLocal = {
            employee_type: data.employee_type,
            prefix: data.prefix,
            first_name: data.first_name,
            display_name: data.display_name,
            middle_name: '',
            last_name: data.last_name,
            email: data.email,
            // gender: 'MALE',
            gender: data.gender,

            business_unit_id: Number(data.business_unit_id),
            approver_ids: employee1 ? employee : null,
            department_id: Number(data.department_id),
            branch_id: Number(data.branch_id),
            branch_name: AllBranches && AllBranches[0]?.name,
            department_name: AllDepartments && AllDepartments[0]?.name,
            // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
            // rm_name:
            //   AllRelationManager &&
            //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
            relation_managers: SelectedRelations ? RelationArray : null,
            // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
            // sc_name:
            //   AllSalesContact &&
            //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
            sales_contacts: SaleDetailsArray,
            adp_associate_id: data.adp_associate_id,
            phone_number: data.phone_number,
            employee_id: employeeIdData,
            ecdb_user_id: ecdbUserIdData,
            prospective_user_id: dataIdprofile.length > 0 ? profileResponseId : null,
            // joining_date: data.joining_date,
            // joining_date: JoiningDate,
            joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            company_code: data.company_code,
            file_number: data.file_number,
            // leaving_date: data.leaving_date,
            // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            qb_employee_name: null,
            qb_employee_id: data.qb_employee_id,
            netsuite_consultant_id: getValues('netSuite_employee_id')
              ? getValues('netSuite_employee_id')
              : null,
            // user_status: data.record_type_status,
            user_status:
              data.record_type_status == undefined
                ? 'Active'
                : data.record_type_status == 'Active'
                ? 'Active'
                : 'InActive',

            // user_status: "ACTIVE",
            work_location: 'Hyderabad',
            work_country: countries[0].name,
            work_state: statesId[0].name,
            work_city: 'Hyderabad',
            // work_street_address: 'test',
            work_status: null,
            zipcode: '123456',
            // email_notifications: data.email_notifications,
            email_notifications:
              data.email_notifications == undefined
                ? true
                : data.email_notifications == 'true'
                ? 'true'
                : 'false',
            push_notifications: true,
            permanent_address: data.permanent_address,
            work_street_address: data.work_street_address,
            visa_status: workData1,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
          }
          setShowBackDrop(true)

          const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal)

          var UpdateIdRes1 =
            insertEmployeeData &&
            insertEmployeeData.data &&
            insertEmployeeData.data[0] &&
            insertEmployeeData.data[0]?.id
          // setInsertDisable(insertEmployeeData.message)
          // setUserUpdateId(insertEmployeeData.data[0].id)
          setUserIdStore(insertEmployeeData?.data[0]?.id)

          setUserNameId(insertEmployeeData?.data[0]?.first_name)
          setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)

          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            setInsertDisable(true)
            setIsEdit(true)

            const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes1})
            setMainTableData(response.data.data)
          }

          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            // return (
            SuccessToast('Time Tracker User Added Successfully')
            setShowBackDrop(false)

            // if (list === false) {
            if (data.first_name == nameData) {
              // setTimeout(function () {
              //   navigate('/masters/manageconsultants')
              // }, 3000)
            }
            // )
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)
        } else {
          var TicketToolArray: any = []
          let EcdbAddUserData = {
            // domain: 'web-timesheets-uat.azurewebsites.net',
            domain: null,
            email: data.email,
            employeeId: employeeIdData,
            firstName: data.first_name,
            displayName: data.display_name,
            // displayName: data.first_name + space + data.last_name,
            jobBoradAccess: true,
            // joiningDate: '11-01-2022',
            // joiningDate: JoiningDate,
            // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
            lastName: data.last_name,
            orgId: id,
            phone: data.phone_number,
            // roleName: 'Employee',
            // toolName: toolName,
            // userStatus: 'ACTIVE',
            userStatus:
              data.record_type_status == undefined
                ? 'ACTIVE'
                : data.record_type_status == 'Active'
                ? 'ACTIVE'
                : 'INACTIVE',
            middleName: null,
            phoneNumber: data.phone_number,
            orgName: orgName,
            // branchId: null,
            // branchName: null,
            // departmentId: null,
            // departmentName: null,
            countryId: null,
            countryName: countries[0].name,
            supervisorId: 0,
            supervisorName: null,
            // gender: '',
            companyCode: data.company_code,
            fileNumber: data.file_number,
            gender: data.gender,

            businessUnitId: Number(data.business_unit_id),
            branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
            branchName: AllBranches && AllBranches[0]?.name,
            departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
            departmentName: AllDepartments && AllDepartments[0]?.name,

            fullName: data.first_name + space + data.last_name,
            linkId: null,
            fullProfile: false,
            editProfile: false,
            teamProfile: false,
            otherFullProfile: false,
            otherEditProfile: false,
            organizationInformation: false,
            otherOrganizations: null,
            signatoryAuthority: false,
            role: null,
            contractsRole: false,
            candidateReferencedBy: null,
            prospectiveRefId: dataIdprofile.length > 0 ? profileResponseId : null,
            empType: data.employee_type,
            // workLocation: 'Andhra Pradesh',
            workLocation: null,
            stateName: statesId[0].name,
            workCity: data.work_city,
            work_street_address: data.work_street_address,
            zipcode: data.zipcode,
            adpAssociateId: data.adp_associate_id,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
            workAuthorizationName: workData1,
            tools: TicketToolArray,
          }
          setShowBackDrop(true)
          const TicketingToolAccess: any = await Apiservices.getTicketingToolAccess() //onboarding.msrcosmos.com/api/tools/getDetails/TicketingTool',
          if (
            TicketingToolAccess &&
            TicketingToolAccess.data &&
            TicketingToolAccess.data.statusCode == 200
          ) {
            let ticketingtools = TicketingToolAccess?.data?.tools
            let end_customer_id = ticketingtools?.filter(
              (each: any) => each.organizationName === localStorage.getItem('org_name')
            )
            if (end_customer_id?.length == 0) {
              var TicketToolObject: any = {
                toolName: 'Timesheet Tracker',
                roleName: 'Employee',
              }
              TicketToolArray.push(TicketToolObject)
            } else {
              var TicketToolObject: any = {
                toolName: 'Timesheet Tracker',
                roleName: 'Employee',
              }
              var TicketToolObject1: any = {
                toolName: 'TicketingTool',
                roleName: 'User',
              }
              TicketToolArray.push(TicketToolObject, TicketToolObject1)
            }
            setShowBackDrop(false)
          }
          setShowBackDrop(false)
          setShowBackDrop(true)

          const editEmployee: any = await Apiservices.addUserSubmitPost(EcdbAddUserData) //(user/save api) added ecdb record

          console.log(EcdbAddUserData, 'hdutfgdfyyf')
          // const editEmployee: any = await Apiservices.addUserPost(EcdbAddUserData)
          setUsers(editEmployee?.userId)
          var ECDB_userid2 = editEmployee.userId

          if (editEmployee && editEmployee.message == 'User Created Successfully') {
            SuccessToast('ECDB User Created Successfully')
            setTimeout(function () {}, 6000)
            setShowBackDrop(false)
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)

          let insertEmployeeLocal = {
            employee_type: data.employee_type,
            prefix: data.prefix,
            first_name: data.first_name,
            display_name: data.display_name,
            middle_name: '',
            last_name: data.last_name,
            email: data.email,
            // gender: '',
            gender: data.gender,

            business_unit_id: Number(data.business_unit_id),
            approver_ids: employee1 ? employee : null,
            branch_id: Number(data.branch_id),
            department_id: Number(data.department_id),
            branch_name: AllBranches && AllBranches[0]?.name,
            department_name: AllDepartments && AllDepartments[0]?.name,
            // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
            // rm_name:
            //   AllRelationManager &&
            //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
            relation_managers: SelectedRelations ? RelationArray : null,
            // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
            // sc_name:
            //   AllSalesContact &&
            //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
            sales_contacts: SaleDetailsArray,
            company_code: data.company_code,
            file_number: data.file_number,
            adp_associate_id: data.adp_associate_id,
            phone_number: data.phone_number,
            employee_id: employeeIdData,
            ecdb_user_id: editEmployee.userId,
            prospective_user_id: dataIdprofile.length > 0 ? profileResponseId : null,
            // joining_date: data.joining_date,
            // joining_date: JoiningDate,
            joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            adp_validated: AdpAssociateIdDataMessage,
            // leaving_date: data.leaving_date,
            // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            qb_employee_name: null,
            qb_employee_id: data.qb_employee_id,
            netsuite_consultant_id: getValues('netSuite_employee_id')
              ? getValues('netSuite_employee_id')
              : null,
            // user_status: data.record_type_status,
            user_status:
              data.record_type_status == undefined
                ? 'Active'
                : data.record_type_status == 'Active'
                ? 'Active'
                : 'InActive',
            // user_status: "ACTIVE",

            work_location: data.work_location,
            work_country: countries[0].name,
            work_state: statesId[0].name,
            work_city: data.work_city,
            // work_street_address: 'test',
            work_status: null,
            zipcode: data.zipcode,
            // email_notifications: data.email_notifications,
            email_notifications:
              data.email_notifications == undefined
                ? true
                : data.email_notifications == 'true'
                ? 'true'
                : 'false',
            push_notifications: true,
            permanent_address: data.permanent_address,
            work_street_address: data.work_street_address,
            visa_status: workData1,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
          }
          setShowBackDrop(true)

          const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal) //added in local tracker

          var UpdateIdRes2 = insertEmployeeData?.data[0]?.id
          setInsertDisable(insertEmployeeData?.message)
          getUserIdDataBasedOnId(insertEmployeeData?.data[0]?.id)
          setUserIdStore(insertEmployeeData?.data[0]?.id)
          setUserNameId(insertEmployeeData?.data[0]?.first_name)
          setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)
          setProspectiveFieldDisable(false)
          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            SuccessToast('Time Tracker Updated Successfully')
            setInsertDisable(true)
            setIsEdit(true)
            const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes2}) //projects2/sync_projects_by_user_id api
            setResponseData(response?.data?.message)
            setMainTableData(response?.data?.data)
            setShowBackDrop(false)
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)
        }
      } else {
        let anil = candidate ? candidate : {}
        let id = localStorage.getItem('logged_in_id')
        let space = ' '
        let role = localStorage.getItem('role_Name')
        let toolName = localStorage.getItem('tool_name')
        let prefixx = localStorage.getItem('org_prefix')
        let orgName = localStorage.getItem('org_name')
        let email = data.email
        setShowBackDrop(true)
        const employeeValid: any = await Apiservices.validateEmployee({orgId: id, email: email})
        setShowBackDrop(false)
        if (employeeValid?.data?.message == 'Already Exists') {
          let checkedEmailData = {
            email: data.email,
            orgId: id,
          }
          setShowBackDrop(true)
          const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)
          setShowBackDrop(false)
          setCheckedEmail(employeeValid1.userId)
          if (employeeValid1 && employeeValid1.message == 'Email Already Exist') {
            let EcdbUpdateUser = {
              // id: employeeValid1.userId,
              id: ECDBUpdateId,
              // domain: 'web-timesheets-uat.azurewebsites.net',
              domain: null,
              email: data.email,
              employeeId: employeeIdData,
              firstName: data.first_name,
              displayName: data.display_name,
              // joiningDate: '11-01-2022',
              // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
              joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

              leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

              lastName: data.last_name,
              orgId: id,
              phone: data.phone_number,
              roleName: 'Employee',
              toolName: toolName,
              // userStatus: 'ACTIVE',
              userStatus:
                data.record_type_status == undefined
                  ? 'ACTIVE'
                  : data.record_type_status == 'Active'
                  ? 'ACTIVE'
                  : 'INACTIVE',
              middleName: null,
              phoneNumber: data.phone_number,
              orgName: orgName,
              // branchId: null,
              // branchName: null,
              // departmentId: null,
              // departmentName: null,
              countryId: null,
              countryName: countries[0].name,
              supervisorId: 0,
              supervisorName: null,
              // gender: '',
              gender: data.gender,
              companyCode: data.company_code,
              fileNumber: data.file_number,
              businessUnitId: Number(data.business_unit_id),
              branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
              branchName: AllBranches && AllBranches[0]?.name,
              departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
              departmentName: AllDepartments && AllDepartments[0]?.name,

              fullName: data.first_name + space + data.last_name,
              linkId: null,
              fullProfile: false,
              editProfile: false,
              teamProfile: false,
              otherFullProfile: false,
              otherEditProfile: false,
              organizationInformation: false,
              otherOrganizations: null,
              signatoryAuthority: false,
              role: null,
              contractsRole: false,
              candidateReferencedBy: null,
              prospectiveRefId: null,
              empType: data.employee_type,
              // workLocation: 'Andhra Pradesh',
              workLocation: null,
              stateName: statesId[0].name,
              workCity: data.work_city,
              workStreetAddress: data.work_street_address,
              zipcode: data.zipcode,
              adpAssociateId: data.adp_associate_id,
              workAuthorizationId: workId,
              // wokAuthorizationId: workId,
              workAuthorizationName: workData1,
              jobBoardAccess: false,
            }
            console.log(EcdbUpdateUser, 'hdutfgdfyyf')
            if (data.first_name !== nameData || lastData !== data.last_name) {
              setShowBackDrop(true)
              const resp: any = await Apiservices.ECDB_UserSearch_URL(employeeValid1.userId, {})
              if (resp && resp.status == 200) {
                if (resp?.data?.pwd) {
                  const sendingTokenForMail: any = await Apiservices.sendingTokenForMail({
                    email: data.email,
                    applicationToolName: 'Timesheet Tracker',
                  })
                  if (sendingTokenForMail && sendingTokenForMail.statusCode == 200) {
                    toast.success('Reset link has sent to your mail please check')
                  }
                }
                setShowBackDrop(false)
              }
              setShowBackDrop(false)
              setShowBackDrop(true)
              const response: any = await Apiservices.updateUser(EcdbUpdateUser)
              setUsers(response?.userId)
              var dfdgdgg = response.userId
              setEcdbUserId(response?.userId)
              if (response && response.message == 'User Updated Successfully') {
                SuccessToast('ECDB User Updated Successfully')
                setShowBackDrop(false)
              } else {
                setShowBackDrop(false)
                toast.error('Something Went Wrong')
              }
              setShowBackDrop(false)
            }

            let insertEmployeeLocal = {
              employee_type: data.employee_type,
              prefix: data.prefix,
              first_name: data.first_name,
              display_name: data.display_name,
              middle_name: '',
              last_name: data.last_name,
              email: data.email,
              // gender: 'MALE',
              gender: data.gender,

              business_unit_id: Number(data.business_unit_id),
              department_id: Number(data.department_id),
              approver_ids: employee1 ? employee : null,
              branch_id: Number(data.branch_id),
              branch_name: AllBranches && AllBranches[0]?.name,
              department_name: AllDepartments && AllDepartments[0]?.name,
              // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
              // rm_name:
              //   AllRelationManager &&
              //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
              relation_managers: SelectedRelations ? RelationArray : null,
              // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
              // sc_name:
              //   AllSalesContact &&
              //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
              sales_contacts: SaleDetailsArray,
              company_code: data.company_code,
              file_number: data.file_number,
              adp_associate_id: data.adp_associate_id,
              phone_number: data.phone_number,
              employee_id: employeeIdData,
              ecdb_user_id: employeeValid1.userId,
              prospective_user_id: null,
              // joining_date: data.joining_date,
              // joining_date: JoiningDate,
              joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,

              // leaving_date: data.leaving_date,
              // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
              leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

              qb_employee_name: null,
              qb_employee_id: data.qb_employee_id,
              netsuite_consultant_id: getValues('netSuite_employee_id')
                ? getValues('netSuite_employee_id')
                : null,
              // user_status: data.record_type_status,
              user_status:
                data.record_type_status == undefined
                  ? 'Active'
                  : data.record_type_status == 'Active'
                  ? 'Active'
                  : 'InActive',
              // user_status: "ACTIVE",

              work_location: 'Hyderabad',
              work_country: countries[0].name,
              work_state: statesId[0].name,
              work_city: 'Hyderabad',
              // work_street_address: 'test',
              work_status: null,
              zipcode: '123456',
              // email_notifications: data.email_notifications,
              email_notifications:
                data.email_notifications == undefined
                  ? true
                  : data.email_notifications == 'true'
                  ? 'true'
                  : 'false',
              push_notifications: true,
              permanent_address: data.permanent_address,
              work_street_address: data.work_street_address,
              visa_status: workData1,
              workAuthorizationId: workId,
              // wokAuthorizationId: workId,
            }
            setShowBackDrop(true)

            const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal)
            var UpdateIdRes3 = insertEmployeeData?.data[0]?.id
            // setUserUpdateId(insertEmployeeData.data[0].id)
            setUserIdStore(insertEmployeeData?.data[0]?.id)
            getUserIdDataBasedOnId(insertEmployeeData?.data[0]?.id)
            setProspectiveFieldDisable(false)
            setUserNameId(insertEmployeeData?.data[0]?.first_name)
            setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)

            setInsertDisable(insertEmployeeData?.message)

            if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
              setInsertDisable(true)
              setIsEdit(true)
              SuccessToast('Time Tracker User Added Successfully')
              const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes3})
              setMainTableData(response?.data?.data)
              setShowBackDrop(false)
            } else {
              setShowBackDrop(false)
              toast.error('Something Went Wrong')
            }
            setShowBackDrop(false)
          }
        } else if (ecdbUserIdData !== null) {
          let EcdbUpdateUser = {
            // id: ecdbUserIdData,
            id: ECDBUpdateId,
            // domain: 'web-timesheets-uat.azurewebsites.net',
            domain: null,
            email: data.email,
            employeeId: employeeIdData,
            firstName: data.first_name,
            displayName: data.display_name,
            // joiningDate: '11-01-2022',
            // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            lastName: data.last_name,
            orgId: id,
            phone: data.phone_number,
            roleName: 'Employee',
            toolName: toolName,
            // userStatus: 'ACTIVE',
            userStatus:
              data.record_type_status == undefined
                ? 'ACTIVE'
                : data.record_type_status == 'Active'
                ? 'ACTIVE'
                : 'INACTIVE',
            middleName: null,
            phoneNumber: data.phone_number,
            orgName: orgName,
            // branchId: null,
            // branchName: null,
            // departmentId: null,
            // departmentName: null,
            countryId: null,
            countryName: countries[0].name,
            supervisorId: 0,
            supervisorName: null,
            // gender: '',
            gender: data.gender,
            companyCode: data.company_code,
            fileNumber: data.file_number,
            businessUnitId: Number(data.business_unit_id),
            branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
            branchName: AllBranches && AllBranches[0]?.name,
            departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
            departmentName: AllDepartments && AllDepartments[0]?.name,

            fullName: data.first_name + space + data.last_name,
            linkId: null,
            fullProfile: false,
            editProfile: false,
            teamProfile: false,
            otherFullProfile: false,
            otherEditProfile: false,
            organizationInformation: false,
            otherOrganizations: null,
            signatoryAuthority: false,
            role: null,
            contractsRole: false,
            candidateReferencedBy: null,
            prospectiveRefId: null,
            empType: data.employee_type,
            // workLocation: 'Andhra Pradesh',
            workLocation: null,
            stateName: statesId[0].name,
            workCity: data.work_city,
            workStreetAddress: data.work_street_address,
            zipcode: data.zipcode,
            adpAssociateId: data.adp_associate_id,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
            workAuthorizationName: workData1,
            jobBoardAccess: false,
          }
          console.log(EcdbUpdateUser, 'hdutfgdfyyf')
          if (data.first_name !== nameData || lastData !== data.last_name) {
            setShowBackDrop(true)
            const resp: any = await Apiservices.ECDB_UserSearch_URL(ecdbUserIdData, {})
            if (resp && resp.status == 200) {
              if (resp?.data?.pwd) {
                const sendingTokenForMail: any = await Apiservices.sendingTokenForMail({
                  email: data.email,
                  applicationToolName: 'Timesheet Tracker',
                })
                if (sendingTokenForMail && sendingTokenForMail.statusCode == 200) {
                  toast.success('Reset link has sent to your mail please check')
                }
              }
              setShowBackDrop(false)
            }
            setShowBackDrop(false)
            setShowBackDrop(true)
            const response: any = await Apiservices.updateUser(EcdbUpdateUser)
            setUsers(response.userId)
            var sjdfjgfg = response.userId
            setEcdbUserId(response?.userId)
            if (response && response.message == 'User Updated Successfully') {
              SuccessToast(' ECDB User Updated Successfully')
              setShowBackDrop(false)
            } else {
              setShowBackDrop(false)
              toast.error('Something Went Wrong')
            }
            setShowBackDrop(false)
          }

          let insertEmployeeLocal = {
            employee_type: data.employee_type,
            prefix: data.prefix,
            first_name: data.first_name,
            display_name: data.display_name,
            middle_name: '',
            last_name: data.last_name,
            email: data.email,
            // gender: 'MALE',
            gender: data.gender,

            business_unit_id: Number(data.business_unit_id),
            department_id: Number(data.department_id),
            approver_ids: employee1 ? employee : null,
            branch_id: Number(data.branch_id),
            branch_name: AllBranches && AllBranches[0]?.name,
            department_name: AllDepartments && AllDepartments[0]?.name,
            // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
            // rm_name:
            //   AllRelationManager &&
            //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
            relation_managers: SelectedRelations ? RelationArray : null,
            // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
            // sc_name:
            //   AllSalesContact &&
            //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
            sales_contacts: SaleDetailsArray,
            company_code: data.company_code,
            file_number: data.file_number,
            adp_associate_id: data.adp_associate_id,
            phone_number: data.phone_number,
            employee_id: employeeIdData,
            ecdb_user_id: ecdbUserIdData,
            prospective_user_id: null,
            // joining_date: data.joining_date,
            // joining_date: JoiningDate,
            joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,

            // leaving_date: data.leaving_date,
            // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            qb_employee_name: null,
            qb_employee_id: data.qb_employee_id,
            netsuite_consultant_id: getValues('netSuite_employee_id')
              ? getValues('netSuite_employee_id')
              : null,
            // user_status: data.record_type_status,
            user_status:
              data.record_type_status == undefined
                ? 'Active'
                : data.record_type_status == 'Active'
                ? 'Active'
                : 'InActive',
            work_location: 'Hyderabad',
            work_country: countries[0].name,
            work_state: statesId[0].name,
            work_city: 'Hyderabad',
            // work_street_address: 'test',
            work_status: null,
            zipcode: '123456',
            // email_notifications: data.email_notifications,
            email_notifications:
              data.email_notifications == undefined
                ? true
                : data.email_notifications == 'true'
                ? 'true'
                : 'false',
            push_notifications: true,
            permanent_address: data.permanent_address,
            work_street_address: data.work_street_address,
            visa_status: workData1,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,

            // user_status: "ACTIVE"
          }
          setShowBackDrop(true)

          const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal)
          var UpdateIdRes4 = insertEmployeeData?.data[0]?.id
          setInsertDisable(insertEmployeeData?.message)
          // setUserUpdateId(insertEmployeeData.data[0].id)
          setUserIdStore(insertEmployeeData?.data[0]?.id)
          getUserIdDataBasedOnId(insertEmployeeData?.data[0]?.id)
          setProspectiveFieldDisable(false)
          setUserNameId(insertEmployeeData?.data[0]?.first_name)
          setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)

          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            setInsertDisable(true)
            setIsEdit(true)
            SuccessToast('Time Tracker User Added Successfully')
            const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes4})
            setMainTableData(response.data.data)
            setShowBackDrop(false)
          }
          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            SuccessToast('Time Tracker User Added Successfully')
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)
        } else {
          var TicketToolArray: any = []
          let EcdbAddUserData = {
            // domain: 'web-timesheets-uat.azurewebsites.net',
            domain: null,
            email: data.email,
            employeeId: employeeIdData,
            firstName: data.first_name,
            displayName: data.display_name,
            // displayName: data.first_name + space + data.last_name,
            jobBoradAccess: true,
            // joiningDate: '11-01-2022',
            // joiningDate: JoiningDate,
            // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
            // joining_date: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
            joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,

            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
            lastName: data.last_name,
            orgId: id,
            phone: data.phone_number,
            // roleName: 'Employee',
            // toolName: toolName,
            // userStatus: 'ACTIVE',
            userStatus:
              data.record_type_status == undefined
                ? 'ACTIVE'
                : data.record_type_status == 'Active'
                ? 'ACTIVE'
                : 'INACTIVE',
            middleName: null,
            phoneNumber: data.phone_number,
            orgName: orgName,
            // branchId: null,
            // branchName: null,
            // departmentId: null,
            // departmentName: null,
            countryId: null,
            countryName: countries[0].name,
            supervisorId: 0,
            supervisorName: null,
            // gender: '',
            gender: data.gender,
            companyCode: data.company_code,
            fileNumber: data.file_number,
            businessUnitId: Number(data.business_unit_id),
            branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
            branchName: AllBranches && AllBranches[0]?.name,
            departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
            departmentName: AllDepartments && AllDepartments[0]?.name,

            fullName: data.first_name + space + data.last_name,
            linkId: null,
            fullProfile: false,
            editProfile: false,
            teamProfile: false,
            otherFullProfile: false,
            otherEditProfile: false,
            organizationInformation: false,
            otherOrganizations: null,
            signatoryAuthority: false,
            role: null,
            contractsRole: false,
            candidateReferencedBy: null,
            prospectiveRefId: null,
            empType: data.employee_type,
            // workLocation: 'Andhra Pradesh',
            workLocation: null,
            stateName: statesId[0].name,
            workCity: data.work_city,
            work_street_address: data.work_street_address,
            zipcode: data.zipcode,
            adpAssociateId: data.adp_associate_id,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,
            workAuthorizationName: workData1,
            tools: TicketToolArray,
          }
          setShowBackDrop(true)
          const TicketingToolAccess: any = await Apiservices.getTicketingToolAccess()
          if (TicketingToolAccess.data.statusCode == 200) {
            let ticketingtools = TicketingToolAccess?.data?.tools
            let end_customer_id = ticketingtools?.filter(
              (each: any) => each.organizationName === localStorage.getItem('org_name')
            )
            if (end_customer_id.length == 0) {
              var TicketToolObject: any = {
                toolName: 'Timesheet Tracker',
                roleName: 'Employee',
              }
              TicketToolArray.push(TicketToolObject)
            } else {
              var TicketToolObject: any = {
                toolName: 'Timesheet Tracker',
                roleName: 'Employee',
              }
              var TicketToolObject1: any = {
                toolName: 'TicketingTool',
                roleName: 'User',
              }
              TicketToolArray.push(TicketToolObject, TicketToolObject1)
            }
            setShowBackDrop(false)
          }
          setShowBackDrop(false)
          setShowBackDrop(true)
          const editEmployee: any = await Apiservices.addUserSubmitPost(EcdbAddUserData)
          console.log(EcdbAddUserData, 'hdutfgdfyyf')
          // const editEmployee: any = await Apiservices.addUserPost(EcdbAddUserData)
          var ECDB_userid1 = editEmployee?.userId
          setUsers(ECDB_userid1)
          // setUserIdData(editEmployee.data[0].userId)

          if (editEmployee && editEmployee.message == 'User Created Successfully') {
            SuccessToast('ECDB User Created Successfully')
            setShowBackDrop(false)
            setTimeout(function () {}, 6000)
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)

          let insertEmployeeLocal = {
            employee_type: data.employee_type,
            prefix: data.prefix,
            first_name: data.first_name,
            display_name: data.display_name,
            middle_name: '',
            last_name: data.last_name,
            email: data.email,
            // gender: '',
            gender: data.gender,

            business_unit_id: Number(data.business_unit_id),
            department_id: Number(data.department_id),
            approver_ids: employee1 ? employee : null,
            branch_id: Number(data.branch_id),
            branch_name: AllBranches && AllBranches[0]?.name,
            department_name: AllDepartments && AllDepartments[0]?.name,
            // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
            // rm_name:
            //   AllRelationManager &&
            //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
            relation_managers: SelectedRelations ? RelationArray : null,
            // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
            // sc_name:
            //   AllSalesContact &&
            //   `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
            sales_contacts: SaleDetailsArray,
            company_code: data.company_code,
            file_number: data.file_number,
            adp_associate_id: data.adp_associate_id,
            phone_number: data.phone_number,
            employee_id: employeeIdData,
            ecdb_user_id: editEmployee.userId,
            prospective_user_id: null,
            // joining_date: data.joining_date,
            // joining_date: JoiningDate,
            joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,

            // leaving_date: data.leaving_date,
            // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
            leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

            qb_employee_name: null,
            qb_employee_id: data.qb_employee_id,
            netsuite_consultant_id: getValues('netSuite_employee_id')
              ? getValues('netSuite_employee_id')
              : null,
            // user_status: data.record_type_status,
            user_status:
              data.record_type_status == undefined
                ? 'Active'
                : data.record_type_status == 'Active'
                ? 'Active'
                : 'InActive',
            work_location: data.work_location,
            work_country: countries[0].name,
            work_state: statesId[0].name,
            work_city: data.work_city,
            // work_street_address: 'test',
            work_status: null,
            zipcode: data.zipcode,
            // email_notifications: data.email_notifications,
            email_notifications:
              data.email_notifications == undefined
                ? true
                : data.email_notifications == 'true'
                ? 'true'
                : 'false',
            push_notifications: true,
            permanent_address: data.permanent_address,
            work_street_address: data.work_street_address,
            visa_status: workData1,
            workAuthorizationId: workId,
            // wokAuthorizationId: workId,

            // user_status: "ACTIVE",
          }
          setShowBackDrop(true)

          const insertEmployeeData: any = await Apiservices.insertEmployee(insertEmployeeLocal)
          var UpdateIdRes6 =
            insertEmployeeData &&
            insertEmployeeData.data &&
            insertEmployeeData.data[0] &&
            insertEmployeeData.data[0].id
          setInsertDisable(insertEmployeeData?.message)
          // setUserUpdateId(insertEmployeeData.data[0].id)
          setUserIdStore(insertEmployeeData?.data[0]?.id)
          getUserIdDataBasedOnId(insertEmployeeData?.data[0]?.id)
          setProspectiveFieldDisable(false)
          setUserNameId(insertEmployeeData?.data[0]?.first_name)
          setUserEcdbId(insertEmployeeData?.data[0]?.ecdb_user_id)

          // setUserIdData(insertEmployeeData.data[0].userId)

          if (insertEmployeeData && insertEmployeeData.message == 'Added Successfully') {
            setInsertDisable(true)
            setIsEdit(true)
            SuccessToast('Time Tracker Updated Successfully')
            const response: any = await Apiservices.projectUserData1({user_id: UpdateIdRes6})
            setResponseData(response?.data?.message)
            setMainTableData(response?.data?.data)
            setShowBackDrop(false)
          } else {
            setShowBackDrop(false)
            toast.error('Something Went Wrong')
          }
          setShowBackDrop(false)
        }
      }
    }
    if (isEdit) {
      if (disableQuickBooks) {
        if (nameData !== data.first_name || lastData !== data.last_name) {
          setList(true)
        } else {
          handleUpdateConsultant(
            data,
            employeeIdData,
            ProjDetailsArray,
            AllBranches,
            AllDepartments,
            RelationArray,
            SelectedRelations,
            // AllSalesContact,
            SaleDetailsArray,
            employee,
            employee1
          )
        }
      } else {
        handleUpdateConsultant(
          data,
          employeeIdData,
          ProjDetailsArray,
          AllBranches,
          AllDepartments,
          RelationArray,
          SelectedRelations,
          // AllSalesContact,
          SaleDetailsArray,
          employee,
          employee1
        )
      }
    }
    setShowBackDrop(false)
    // if (isLogin == 'Approver') {
    //   window.location.href = '/timetracker/approver'
    //   setTimeout(function () {
    //     navigate('/timetracker/approver')
    //   }, 3000)
    // }
    // props.handleClose('pagereload')
    setShowBackDrop(false)
  }

  const handleUpdateConsultant = async (
    data: any,
    employeeIdData: any,
    ProjDetailsArray: any,
    AllBranches: any,
    AllDepartments: any,
    RelationArray: any,
    SelectedRelations: any,
    // AllSalesContact: any,
    SaleDetailsArray: any,
    employee: any,
    employee1: any
  ) => {
    if (getValues('netSuite_employee_id')) {
      let AllDepts = DepartmentList.filter(
        (test: any) => test.ecdb_department_id == getValues('department_id')
      )
      let AllBus = BusinessUnitsList.filter(
        (test: any) => test.business_unit_id == getValues('business_unit_id')
      )
      let NewObj: any = {
        employee_type: selectedItem,
        first_name: candidate.first_name,
        middle_name: candidate.middle_name,
        last_name: candidate.last_name,
        subsidiaryName: localStorage.getItem('subsidary_name'),
        email: getValues('email'),
        phone_number: getValues('phone_number'),
        department_name: AllDepts && AllDepts[0]?.name,
        // department_name: 'HR',
        country_name: countries[0].name,
        city: getValues('work_city'),
        state_name: statesId[0].name,
        joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
        zipcode: getValues('zipcode'),
        employee_id: getValues('employee_id'),
        isjobresource: true,
        classificationName: AllBus && AllBus[0]?.name,
        // classificationName: 'BU1',
      }

      let keys1 = Object.keys(OldObj)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldObj[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      }

      if (arr_obj.length > 0) {
        const upda: any = await Apiservices.updateNetSuite(getValues('netSuite_employee_id'), {
          arr_obj,
        })
        if (upda.statusCode == 200) {
          toast.success('NetSuite details are updated successfully')
        }
      }
    }

    if (employeeTypeData !== 'Internal') {
      // const res = await axios.get<any>(
      //   `https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId/${organizationId}?name=${data.email}`
      // )
      // setShowBackDrop(true)
      // const res: any = await Apiservices.getUpdateSearchCandidate({  //comment api loading more time in prod(prospective id field available)
      //   //prospective candidate search based on email
      //   name: data.email,
      // })
      // if (res && res.data && res.data.statusCode == 200) {
      //   setShowBackDrop(false)
      //   if (res?.data?.profileResponse?.length == 0) {
      //     var passPropsID: any = prospUpdateId //fetching prosp id from getUserbyId
      //   } else if (prospUpdateId !== res?.data?.profileResponse[0]?.id) {
      //     var passPropsID: any = prospUpdateId
      //   } else {
      //     var passPropsID: any = prospUpdateId
      //   }
      // } else {
      //   setShowBackDrop(false)
      //   toast.error('Something Went Wrong')
      // }
      // setShowBackDrop(false)
      var passPropsID: any = prospUpdateId
      if (passPropsID) {
        let pospData1 = {
          candidateId: passPropsID,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phone: data.phone_number,
          // gender: genderName,
        }

        //prospects-uat.msrcosmos.com/prospective/profile/updateCandidateHrms
        setShowBackDrop(true)
        const employeeValid1: any = await Apiservices.updateUserPros(pospData1) //updated candidate in prospective
        if (employeeValid1 && employeeValid1.statusCode == 200) {
          if (employeeValid1 && employeeValid1.message == 'Candidate Updated Successfully') {
            SuccessToast('Prospective DB Updated Successfully ')
          }
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
      }
      data.user_status = data.record_type_status
      data.id = data.id
      let postObj: any = {}
      let orgName = localStorage.getItem('org_name')
      let toolName = localStorage.getItem('tool_name')
      postObj['joiningDate'] = moment(data.joining_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      postObj['firstName'] = data.first_name
      postObj['prefix'] = data.prefix
      postObj['adp_associate_id'] = data.adp_associate_id
      postObj['lastName'] = data.last_name
      postObj['email'] = data.email
      postObj['employeeId'] = data.employee_id
      postObj['phoneNumber'] = data.phone_number
      postObj['id'] = data.ecdb_user_id
      postObj['orgId'] = data.org_id
      postObj['orgName'] = orgName
      postObj['roleName'] = 'Employee'
      postObj['toolName'] = toolName
      postObj['userStatus'] = 'ACTIVE'
      postObj['domain'] = 'web-timesheets-uat.azurewebsites.net'

      let email: any = data.email
      let id = localStorage.getItem('logged_in_id')
      let space = ' '
      setShowBackDrop(true)
      const employeeValid: any = await Apiservices.validateEmployee({orgId: id, email: email}) //validating email in ecdb
      setShowBackDrop(false)

      if (employeeValid && employeeValid?.data?.message == 'Already Exists') {
        //if email exist call check email
        let checkedEmailData = {
          email: data.email,
          orgId: id,
        }
        setShowBackDrop(true)
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData) //fetching ecdb id
        setShowBackDrop(false)
        if (employeeValid1?.message == 'Email Not Exist') {
          ErrorToast('Email Not Exist')
        }
        let userIdRes = employeeValid1.userId //fetching ecdb id
        setCheckedEmail(employeeValid1?.userId)
        let EcdbUpdateUser = {
          // id:  userIdRes,
          id: ECDBUpdateId,
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          // employeeId: prefixx + '000' + Math.random().toString().slice(2, 5),
          employeeId: data.employee_id,

          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,

          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: passPropsID,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          workAuthorizationId: workId,
          // wokAuthorizationId: workId,

          // workAuthorizationName: workData1,
          workAuthorizationName: workData1 == '' ? visaStatus : workData1,
          jobBoardAccess: false,
        }
        setShowBackDrop(true)
        const response: any = await Apiservices.updateUser(EcdbUpdateUser) //updating ecdb(updateUser api)
        if (response && response.statusCode == 200) {
          var ecdb_user_iddata = response?.userId
          if (response && response.message == 'User Updated Successfully') {
            SuccessToast(' ECDB User Updated Successfully')
          }
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)

        let editEmployeeLocal = {
          // id: userId,
          id: userIdData11,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,
          company_code: data.company_code,
          file_number: data.file_number,
          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,

          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,
          // ecdb_user_id: userIdRes,
          ecdb_user_id: ECDBUpdateId,
          prospective_user_id: passPropsID,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,
          work_location: data.work_location,
          work_country: countries[0].name,
          work_state: statesId[0].name,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,
          adp_validated: AdpAssociateIdDataMessage,
          push_notifications: true,
          permanent_address: data.permanent_address,
          work_street_address: data.work_street_address,
          visa_status: workData1 == '' ? visaStatus : workData1,
        }
        let projectnameUpdated = {
          user_id: userIdData11,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }

        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal) //updating our local tracker
        if (res && res.isError == false) {
          if (res && res.message == 'Updated Successfully') {
            SuccessToast('Time Tracker Updated Successfully')
            let data5: any = {
              employee_type: res.data[0]?.employee_type,
              first_name: res.data[0]?.first_name,
              middle_name: res.data[0]?.middle_name,
              last_name: res.data[0]?.last_name,
              subsidiaryName: localStorage.getItem('subsidary_name'),
              email: res.data[0]?.email,
              phone_number: res.data[0]?.phone_number,
              department_name: res.data[0]?.department_name,
              country_name: res.data[0]?.work_country,
              city: res.data[0]?.work_city,
              state_name: res.data[0]?.work_state,
              joining_date: res.data[0]?.joining_date,
              zipcode: res.data[0]?.zipcode,
              employee_id: res.data[0]?.employee_id,
              isjobresource: true,
              // classificationName: AllBus && AllBus[0]?.name,
              classificationName: res.data[0]?.business_unit_name,
            }
            setOldObj(data5)
          }
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        setShowBackDrop(true)
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated) //update_project_names(api)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userIdData11}) //get_projects_by_user_id
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)
        if (ecdbData !== null) {
          //if ecdb id should not be null
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1) //userECDB/editUserStatus
          //  (data.record_type_status == 'Inactive')  INACTIVE
        }
      } else if (ECDBUpdateId !== null) {
        let EcdbUpdateUser = {
          id: ECDBUpdateId,
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          // employeeId: prefixx + '000' + Math.random().toString().slice(2, 5),
          employeeId: data.employee_id,

          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: passPropsID,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          jobBoardAccess: false,
        }

        setShowBackDrop(true)
        const response: any = await Apiservices.updateUser(EcdbUpdateUser) //updating ecdb(updateUser api)
        var ecdb_user_iddata1 = response?.userId
        if (response && response.message == 'User Updated Successfully') {
          setShowBackDrop(false)
          SuccessToast(' ECDB User Updated Successfully')
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        let editEmployeeLocal = {
          id: userId,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,

          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,
          company_code: data.company_code,
          file_number: data.file_number,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,

          ecdb_user_id: ECDBUpdateId,
          prospective_user_id: passPropsID,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,
          work_location: data.work_location,
          work_country: countries[0].name,
          work_state: statesId[0].name,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,

          push_notifications: true,
          permanent_address: data.permanent_address,
          work_street_address: data.work_street_address,
          visa_status: workData1,
        }
        let projectnameUpdated = {
          user_id: userId,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }
        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal)
        if (res && res.message == 'Updated Successfully') {
          let data5: any = {
            employee_type: res.data[0]?.employee_type,
            first_name: res.data[0]?.first_name,
            middle_name: res.data[0]?.middle_name,
            last_name: res.data[0]?.last_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            email: res.data[0]?.email,
            phone_number: res.data[0]?.phone_number,
            department_name: res.data[0]?.department_name,
            country_name: res.data[0]?.work_country,
            city: res.data[0]?.work_city,
            state_name: res.data[0]?.work_state,
            joining_date: res.data[0]?.joining_date,
            zipcode: res.data[0]?.zipcode,
            employee_id: res.data[0]?.employee_id,
            isjobresource: true,
            // classificationName: AllBus && AllBus[0]?.name,
            classificationName: res.data[0]?.business_unit_name,
          }
          setOldObj(data5)
          SuccessToast('Time Tracker Updated Successfully')
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        setShowBackDrop(true)
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userId})
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)
        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1)
          //data.record_type_status == 'Inactive'
        }
      } else {
        let EcdbAddUserData = {
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          employeeId: employeeIdData,
          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joining_date: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,
          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: passPropsID,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          workAuthorizationId: workId,
          // wokAuthorizationId: workId,
          // workAuthorizationName: workData1,
          workAuthorizationName: workData1 == '' ? visaStatus : workData1,
        }
        console.log(EcdbAddUserData, 'hdutfgdfyyf')
        setShowBackDrop(true)
        const editEmployee: any = await Apiservices.addUserPost(EcdbAddUserData) //user/addUser api in ecdb
        setShowBackDrop(false)
        let ECDB_userid = editEmployee?.userId
        let editEmployeeLocal = {
          id: userId,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,

          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,
          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          company_code: data.company_code,
          file_number: data.file_number,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,

          // ecdb_user_id: ECDB_userid,
          ecdb_user_id: ECDBUpdateId,
          prospective_user_id: passPropsID,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,
          work_street_address: data.work_street_address,
          work_country: data.work_country,
          work_state: data.work_state,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,

          push_notifications: true,
          // visa_status: workData1,
          visa_status: workData1 == '' ? visaStatus : workData1,
        }
        let projectnameUpdated = {
          user_id: userId,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }
        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal)
        if (res && res.isError == false) {
          let data5: any = {
            employee_type: res.data[0]?.employee_type,
            first_name: res.data[0]?.first_name,
            middle_name: res.data[0]?.middle_name,
            last_name: res.data[0]?.last_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            email: res.data[0]?.email,
            phone_number: res.data[0]?.phone_number,
            department_name: res.data[0]?.department_name,
            country_name: res.data[0]?.work_country,
            city: res.data[0]?.work_city,
            state_name: res.data[0]?.work_state,
            joining_date: res.data[0]?.joining_date,
            zipcode: res.data[0]?.zipcode,
            employee_id: res.data[0]?.employee_id,
            isjobresource: true,
            // classificationName: AllBus && AllBus[0]?.name,
            classificationName: res.data[0]?.business_unit_name,
          }
          setOldObj(data5)
          if (res && res.message == 'Updated Successfully') {
            SuccessToast('Time Tracker Updated Successfully')
          }
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userId})
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)
        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1)
          //data.record_type_status == 'Inactive'
        }
        SuccessToast(res?.message)
      }
    } else {
      let orgName = localStorage.getItem('org_name')
      let toolName = localStorage.getItem('tool_name')
      let email: any = data.email
      let id = localStorage.getItem('logged_in_id')
      let space = ' '
      setShowBackDrop(true)
      const employeeValid: any = await Apiservices.validateEmployee({orgId: id, email: email})
      setShowBackDrop(false)
      if (employeeValid && employeeValid.data && employeeValid.data.message == 'Already Exists') {
        let checkedEmailData = {
          email: data.email,
          orgId: id,
        }
        setShowBackDrop(true)
        const employeeValid1: any = await Apiservices.checkEmail(checkedEmailData)
        setShowBackDrop(false)
        let userIdRes = employeeValid1?.userId
        setCheckedEmail(employeeValid1?.userId)
        let EcdbUpdateUser = {
          // id: userIdRes,
          id: ECDBUpdateId,
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          // employeeId: prefixx + '000' + Math.random().toString().slice(2, 5),
          employeeId: data.employee_id,

          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,
          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: null,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          workAuthorizationId: workId,
          // wokAuthorizationId: workId,
          // workAuthorizationName: workData1,
          workAuthorizationName: workData1 == '' ? visaStatus : workData1,
          jobBoardAccess: false,
        }
        setShowBackDrop(true)
        const response: any = await Apiservices.updateUser(EcdbUpdateUser)
        setShowBackDrop(false)
        var ecdb_user_iddata2 = response.userId
        if (response && response.message == 'User Updated Successfully') {
          SuccessToast(' ECDB User Updated Successfully')
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }

        let editEmployeeLocal = {
          // id: userId,
          id: userIdData11,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,

          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,
          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          company_code: data.company_code,
          file_number: data.file_number,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,

          // ecdb_user_id: userIdRes,
          ecdb_user_id: ECDBUpdateId,

          prospective_user_id: null,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,

          work_location: data.work_location,
          work_country: countries[0].name,
          work_state: statesId[0].name,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,

          push_notifications: true,
          permanent_address: data.permanent_address,
          work_street_address: data.work_street_address,
          // visa_status: workData1,
          visa_status: workData1 == '' ? visaStatus : workData1,
        }
        let projectnameUpdated = {
          user_id: userIdData11,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }
        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal)
        if (res && res.message == 'Updated Successfully') {
          let data5: any = {
            employee_type: res.data[0]?.employee_type,
            first_name: res.data[0]?.first_name,
            middle_name: res.data[0]?.middle_name,
            last_name: res.data[0]?.last_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            email: res.data[0]?.email,
            phone_number: res.data[0]?.phone_number,
            department_name: res.data[0]?.department_name,
            country_name: res.data[0]?.work_country,
            city: res.data[0]?.work_city,
            state_name: res.data[0]?.work_state,
            joining_date: res.data[0]?.joining_date,
            zipcode: res.data[0]?.zipcode,
            employee_id: res.data[0]?.employee_id,
            isjobresource: true,
            // classificationName: AllBus && AllBus[0]?.name,
            classificationName: res.data[0]?.business_unit_name,
          }
          setOldObj(data5)
          SuccessToast('Time Tracker Updated Successfully')
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        setShowBackDrop(true)
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userIdData11})
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)
        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const res: any = await Apiservices.updateUserStatusData(data1)
        }
      } else if (ECDBUpdateId !== null) {
        let EcdbUpdateUser = {
          id: ECDBUpdateId,
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          // employeeId: prefixx + '000' + Math.random().toString().slice(2, 5),
          employeeId: data.employee_id,

          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,
          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: null,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          workAuthorizationId: workId,
          // wokAuthorizationId: workId,
          // workAuthorizationName: workData1,
          workAuthorizationName: workData1 == '' ? visaStatus : workData1,
          jobBoardAccess: false,
        }
        console.log(EcdbUpdateUser, 'hdutfgdfyyf')
        setShowBackDrop(true)
        const response: any = await Apiservices.updateUser(EcdbUpdateUser)
        var ecdb_user_iddata3 = response.userId
        if (response && response.message == 'User Updated Successfully') {
          SuccessToast(' ECDB User Updated Successfully')
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1)
        }

        let editEmployeeLocal = {
          id: userId,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,

          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,
          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          company_code: data.company_code,
          file_number: data.file_number,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,

          ecdb_user_id: ECDBUpdateId,
          prospective_user_id: null,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,

          work_location: data.work_location,
          work_country: countries[0].name,
          work_state: statesId[0].name,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,

          push_notifications: true,
          permanent_address: data.permanent_address,
          work_street_address: data.work_street_address,
          // visa_status: workData1,
          visa_status: workData1 == '' ? visaStatus : workData1,
        }

        let projectnameUpdated = {
          user_id: userId,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }
        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal)
        if (res && res.message == 'Updated Successfully') {
          let data5: any = {
            employee_type: res.data[0]?.employee_type,
            first_name: res.data[0]?.first_name,
            middle_name: res.data[0]?.middle_name,
            last_name: res.data[0]?.last_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            email: res.data[0]?.email,
            phone_number: res.data[0]?.phone_number,
            department_name: res.data[0]?.department_name,
            country_name: res.data[0]?.work_country,
            city: res.data[0]?.work_city,
            state_name: res.data[0]?.work_state,
            joining_date: res.data[0]?.joining_date,
            zipcode: res.data[0]?.zipcode,
            employee_id: res.data[0]?.employee_id,
            isjobresource: true,
            // classificationName: AllBus && AllBus[0]?.name,
            classificationName: res.data[0]?.business_unit_name,
          }
          setOldObj(data5)
          SuccessToast('Time Tracker Updated Successfully')
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        setShowBackDrop(false)
        setShowBackDrop(true)
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userId})
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)

        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1)
        }
      } else {
        let EcdbAddUserData = {
          // domain: 'web-timesheets-uat.azurewebsites.net',
          domain: null,
          email: data.email,
          employeeId: employeeIdData,
          firstName: data.first_name,
          displayName: data.display_name,
          // joiningDate: '11-01-2022',
          // joiningDate: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          // joining_date: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          // joiningDate: JoiningDate ? moment(JoiningDate).format('MM-DD-YYYY') : null,
          joiningDate: JoiningDate
            ? moment(JoiningDate).format('MM-DD-YYYY') == '01-01-1970'
              ? null
              : moment(JoiningDate).format('MM-DD-YYYY')
            : null,

          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,
          lastName: data.last_name,
          orgId: id,
          phone: data.phone_number,
          roleName: 'Employee',
          toolName: toolName,
          // userStatus: 'ACTIVE',
          userStatus:
            data.record_type_status == undefined
              ? 'ACTIVE'
              : data.record_type_status == 'Active'
              ? 'ACTIVE'
              : 'INACTIVE',
          middleName: null,
          phoneNumber: data.phone_number,
          orgName: orgName,
          // branchId: null,
          // branchName: null,
          // departmentId: null,
          // departmentName: null,
          countryId: null,
          countryName: countries[0].name,
          supervisorId: 0,
          supervisorName: null,
          // gender: '',
          gender: data.gender,
          companyCode: data.company_code,
          fileNumber: data.file_number,
          businessUnitId: Number(data.business_unit_id),
          branchId: Number(AllBranches && AllBranches[0]?.ecdb_branch_id),
          branchName: AllBranches && AllBranches[0]?.name,
          departmentId: Number(AllDepartments && AllDepartments[0]?.ecdb_department_id),
          departmentName: AllDepartments && AllDepartments[0]?.name,

          fullName: data.first_name + space + data.last_name,
          linkId: null,
          fullProfile: false,
          editProfile: false,
          teamProfile: false,
          otherFullProfile: false,
          otherEditProfile: false,
          organizationInformation: false,
          otherOrganizations: null,
          signatoryAuthority: false,
          role: null,
          contractsRole: false,
          candidateReferencedBy: null,
          prospectiveRefId: null,
          empType: data.employee_type,
          // workLocation: 'Andhra Pradesh',
          workLocation: null,
          stateName: statesId[0].name,
          workCity: data.work_city,
          work_street_address: data.work_street_address,
          zipcode: data.zipcode,
          adpAssociateId: data.adp_associate_id,
          workAuthorizationId: workId,
          // wokAuthorizationId: workId,
          // workAuthorizationName: workData1,
          workAuthorizationName: workData1 == '' ? visaStatus : workData1,
        }
        console.log(EcdbAddUserData, 'hdutfgdfyyf')
        setShowBackDrop(true)
        const editEmployee: any = await Apiservices.addUserPost(EcdbAddUserData) //user/addUser api in ecdb
        setShowBackDrop(false)
        let ECDB_userid = editEmployee.userId
        let editEmployeeLocal = {
          id: userId,
          employee_type: data.employee_type,
          prefix: data.prefix,
          first_name: data.first_name,
          display_name: data.display_name,
          middle_name: '',
          last_name: data.last_name,
          email: data.email,
          // gender: 'MALE',
          gender: data.gender,

          business_unit_id: Number(data.business_unit_id),
          department_id: Number(data.department_id),
          approver_ids: employee1 ? employee : null,
          branch_id: Number(data.branch_id),
          branch_name: AllBranches && AllBranches[0]?.name,
          department_name: AllDepartments && AllDepartments[0]?.name,
          // rm_id: AllRelationManager && AllRelationManager[0]?.userId,
          // rm_name:
          //   AllRelationManager &&
          //   `${AllRelationManager[0]?.firstName}  ${AllRelationManager[0]?.lastName}`,
          relation_managers: SelectedRelations ? RelationArray : null,
          // sc_id: AllSalesContact && AllSalesContact[0]?.userId,
          // sc_name:
          //   AllSalesContact && `${AllSalesContact[0]?.firstName}  ${AllSalesContact[0]?.lastName}`,
          sales_contacts: SaleDetailsArray,
          company_code: data.company_code,
          file_number: data.file_number,
          adp_associate_id: data.adp_associate_id,
          phone_number: data.phone_number,
          // employee_id: employeeIdData,
          employee_id: data.employee_id,

          // ecdb_user_id: ECDB_userid,
          ecdb_user_id: ECDBUpdateId,
          prospective_user_id: null,
          // joining_date: data.joining_date,
          // joining_date: JoiningDate,
          // joining_date: JoiningDate ? moment(JoiningDate).format('YYYY-MM-DD') : null,
          joining_date: JoiningDate
            ? moment(JoiningDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(JoiningDate).format('YYYY-MM-DD')
            : null,

          // leaving_date: data.leaving_date,
          // leaving_date: data.leaving_date == '' ? null : data.leaving_date,
          // leaving_date: LeavingDate ? moment(LeavingDate).format('YYYY-MM-DD') : null,
          leaving_date: LeavingDate
            ? moment(LeavingDate).format('YYYY-MM-DD') == '1970-01-01'
              ? null
              : moment(LeavingDate).format('YYYY-MM-DD')
            : null,

          qb_employee_name: data.qb_employee_name,
          qb_employee_id: data.qb_employee_id,
          netsuite_consultant_id: getValues('netSuite_employee_id')
            ? getValues('netSuite_employee_id')
            : null,
          user_status: data.record_type_status,

          work_street_address: data.work_street_address,
          work_country: data.work_country,
          work_state: data.work_state,
          work_city: data.work_city,
          // work_street_address: 'test',
          work_status: null,
          zipcode: data.zipcode,
          email_notifications: data.email_notifications,

          push_notifications: true,
          // visa_status: workData1,
          visa_status: workData1 == '' ? visaStatus : workData1,
        }

        let projectnameUpdated = {
          user_id: userId,
          // projects: weekDayDataEntry,
          projects: ProjDetailsArray,
        }
        setShowBackDrop(true)
        const res: any = await Apiservices.updateEmployee(editEmployeeLocal)
        if (res && res.isError == false) {
          let data5: any = {
            employee_type: res.data[0]?.employee_type,
            first_name: res.data[0]?.first_name,
            middle_name: res.data[0]?.middle_name,
            last_name: res.data[0]?.last_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            email: res.data[0]?.email,
            phone_number: res.data[0]?.phone_number,
            department_name: res.data[0]?.department_name,
            country_name: res.data[0]?.work_country,
            city: res.data[0]?.work_city,
            state_name: res.data[0]?.work_state,
            joining_date: res.data[0]?.joining_date,
            zipcode: res.data[0]?.zipcode,
            employee_id: res.data[0]?.employee_id,
            isjobresource: true,
            // classificationName: AllBus && AllBus[0]?.name,
            classificationName: res.data[0]?.business_unit_name,
          }
          setOldObj(data5)
          if (res && res.message == 'Updated Successfully') {
            SuccessToast('Time Tracker Updated Successfully')
          }
          setShowBackDrop(false)
        } else {
          setShowBackDrop(false)
          toast.error('Something Went Wrong')
        }
        const res1: any = await Apiservices.updateProjectNames(projectnameUpdated)
        const res2: any = await Apiservices.updateUserEmpId({user_id: userId})
        setShowBackDrop(false)
        setMainTableData(res2?.data?.data)
        if (ecdbData !== null) {
          let data1 = {
            // id: ecdbData,
            userId: ecdbData,
            userStatus: data.record_type_status == 'Active' ? 'ACTIVE' : 'INACTIVE',
            // dateOfLeaving: LeavingDate ? moment(LeavingDate).format('MM-DD-YYYY') : null,
            dateOfLeaving: LeavingDate
              ? moment(LeavingDate).format('MM-DD-YYYY') == '01-01-1970'
                ? null
                : moment(LeavingDate).format('MM-DD-YYYY')
              : null,
          }
          const userStatus: any = await Apiservices.updateUserStatusData(data1)
        }
      }
    }
    if (isLogin == 'Approver') {
      window.location.href = '/timetracker/approver'
      setTimeout(function () {
        navigate('/timetracker/approver')
      }, 3000)
    }
  }

  const checkInput = (e: any) => {
    const onlyDigits = e.target.value.replace(/\D/g, '')
    setNumberData(onlyDigits)
  }

  const checkADP = async (e: any, blur_event: any) => {
    // setShowBackDrop(true)
    // setAdpCheckbox(false)
    setAdpAssociateIdDataMessage(false)

    setButtonAdpDisable(true)
    setButtonAdpUpdateDisable(true)
    setButtonAdpDisableUpdate(true)
    setCheckAdpData(e.target.value)
    if (e.target.value.length == 0) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
    if (blur_event === 'Blur') {
      if (!isEdit) {
        const employeeValid1: any = await Apiservices.adpAssociateIdDataCheck({
          adp_associate_id: e.target.value,
        })
        if (employeeValid1.data.message == 'Records Found') {
          setMessage(true)
          ErrorToast('ADP Associate ID Already Existed')
        } else {
          setCheckAdpData(e.target.value)
          setValue('adp_associate_id', e.target.value)
          setMessage(false)
        }
      }
      if (isEdit) {
        const employeeValid1: any = await Apiservices.adpAssociateIdDataUpdateCheck({
          id: userUpdateId,
          adp_associate_id: checkAdpData,
        })
        if (employeeValid1.data.message == 'Records Found') {
          ErrorToast('ADP Associate ID Already Existed')
          setMessage(true)
        } else {
          setMessage(false)
          setCheckAdpData(checkAdpData)
          setValue('adp_associate_id', e.target.value)
        }
      }
    }
    setShowBackDrop(false)
  }

  const checkADP1 = async (e: any) => {
    setValidateButton(true)
    // setButtonAdpDisable(false)
    setButtonAdpDisableUpdate(false)

    setShowBackDrop(true)

    if (isEdit) {
      const employeeValid1: any = await Apiservices.adpAssociateIdDataCheck({
        id: userUpdateId,
        adp_associate_id: checkAdpData,
      })

      if (employeeValid1.data.message == 'No Records Found') {
        const employeeValid: any = await Apiservices.adpAssociateIdData({workerid: checkAdpData})
        if (employeeValid?.data?.message == 'No Records Found') {
          setAdpAssociateIdDataMessage(false)
          setShowButton(true)
          setButtonAdpUpdateDisable(true)
        } else {
          setShowButton(true)
          setAdpAssociateIdDataMessage(true)
          setButtonAdpUpdateDisable(false)
        }
        if (employeeValid.data.message == 'No Records Found') {
          ErrorToast(
            'No records Found with the provided ADP Associate ID. Please get added the employee in ADP and proceed to Onboard'
          )
          setadpDisabledData(true)
        } else {
          setadpDisabledData(false)

          setViewShow1(true)
          setCheckAdpData(checkAdpData)
        }
        setShowBackDrop(false)
      } else {
        setShowBackDrop(false)
        setShowButton(true)

        // setadpDisabledData(true)
        ErrorToast('This Record Already Existed')
      }
      setShowBackDrop(false)
    }

    if (!isEdit) {
      const employeeValid1: any = await Apiservices.adpAssociateIdDataCheck({
        adp_associate_id: checkAdpData,
      })

      if (employeeValid1.data.message == 'No Records Found') {
        const employeeValid: any = await Apiservices.adpAssociateIdData({workerid: checkAdpData})
        if (employeeValid?.data?.message == 'No Records Found') {
          setAdpAssociateIdDataMessage(false)
          setShowButton(true)
          setButtonAdpDisable(true)
        } else {
          setShowButton(true)
          setAdpAssociateIdDataMessage(true)
          setButtonAdpDisable(false)
        }
        if (employeeValid.data.message == 'No Records Found') {
          ErrorToast(
            'No records Found with the provided ADP Associate ID. Please get added the employee in ADP and proceed to Onboard'
          )
          setadpDisabledData(true)
          setAdpCheckbox(false)
        } else {
          // setadpDisabledData(true)
          setAdpCheckbox(true)

          setViewShow1(true)
          setCheckAdpData(checkAdpData)
          setadpDisabledData(false)
        }
      } else {
        setShowButton(true)
        setadpDisabledData(true)
        ErrorToast('This Record Already Existed')
      }
    }

    //   if (employeeValid1.data.message == "No Records Found") {
    //
    //     const employeeValid: any = await Apiservices.adpAssociateIdData({ workerid: checkAdpData })

    //
    //     if (employeeValid.data.message !== "No Records Found") {
    //       setadpDisabledData(true)
    //
    //       setViewShow1(true)
    //
    //       // setShowBackDrop(true)
    //       setCheckAdpData(checkAdpData)
    //     }
    //     else {
    //

    //       ErrorToast('No records Found with the provided ADP Associate ID. Please get added the employee in ADP and proceed to Onboard')
    //       setadpDisabledData(true)
    //     }
    //   }
    //   else {
    //     ErrorToast('This Record Already Existed')
    //   }
    //   setShowBackDrop(false)
    // }
    setShowBackDrop(false)
    // const employeeValid: any = await Apiservices.adpAssociateIdData({ workerid: checkAdpData })
    //
    // if (employeeValid.data.placement_api_response.message == 'No Record with the given workerid') {
    //   ErrorToast('ECDB Id Exist in Time Tracker')
    //   setAdpMessage(true)
    //   setViewShow(true)

    // } else {
    //   setAdpMessage(false)
    //   SuccessToast("ADP Details Fetched SuccessFully")
    // }
    // setAdpMessage(employeeValid.data.placement_api_response.message)
    // setShowBackDrop(false)

    // setAdpFirstName(employeeValid.data.data[0].LegalFamilyName)
    // setAdpLastName(employeeValid.data.data[0].LegalGivenName)
    // setAdpStatus(employeeValid.data.data[0].workerStatusCodeValue)
    // setAdpDate(employeeValid.data.data[0].OriginalHireDate)
    // setValue('first_name', employeeValid.data.data[0].LegalFamilyName)
    // setValue('last_name', employeeValid.data.data[0].LegalGivenName)
    // setValue('record_type_status', employeeValid.data.data[0].workerStatusCodeValue)
    // setValue('joining_date', employeeValid.data.data[0].OriginalHireDate)
    // const value = e.target.value
    // const regex = /^[0-9a-zA-Z(\-)]+$/ //this will admit letters, numbers and dashes
    // if (value.match(regex) || value === '') {
    //   setADPID(value)
    // }
  }

  const checkZip = (e: any) => {
    const value = e.target.value
    const regex = /^[0-9a-zA-Z(\-)]+$/ //this will admit letters, numbers and dashes
    if (value.match(regex) || value === '') {
      setZIP(value)
    }
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const handleChange5 = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid')
    } else {
      setError(null)
    }

    setEmail(event.target.value)
  }

  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }

  const workData = (e: any) => {
    // setWorkData1(e.target.value)

    // const str = e.target.value;

    // const lastWord = str.split(' ').pop();
    // const firstWord = str.split(' ').shift();

    let id = workAuthorizationData.find((obj: any) => obj.workAuthorizationName === e.target.value)

    id = id ? id.workAuthorizationId : null

    setWorkId(id)
    setWorkData1(e.target.value)
  }

  const handleChange2 = (e: any) => {
    const result = e.target.value.replace(/[^\w\s]/gi, '')
    setSelectedItem(result)
  }

  const btnValue = (btnVal: any) => {
    if (btnVal === 'ok') {
    }
  }
  const handleCancel = () => {
    if (customermessage == 'QB Customer and client name should be same value') {
      // setValue('qb_customer_name', '')
    } else {
      // setValue('qb_project_name', '')
    }
    setList(false)
  }

  const handleBusinessUnit = async (business_id: any) => {
    setApproverUnitsList([])
    setSelectConsultant([])
    setValue('business_unit_id', business_id)
    if (business_id) {
      const res: any = await Apiservices.get_approvers_list_by_business_unit_id({
        business_unit_id: business_id,
      })
      if (res && res.status == 200) {
        if (res?.data?.data) {
          setApproverUnitsList(res.data.data)
        }
      }
      const response: any = await Apiservices.get_relation_managers_list({
        business_unit_id: business_id,
      })
      if (response && response.data && response.data.isError == false) {
        if (response?.data?.data) {
          setRelationDetails(response.data.data)
        }
      }
    }
  }

  const handleRelManager = (relationManager: any) => {
    setValue('relation_manager', relationManager)
  }
  const handleSalesManager = (saleManager: any) => {
    setValue('sales_contact', saleManager)
  }
  const handleBranch = async (branch_id: any) => {
    setValue('branch_id', branch_id)
  }
  const handleDepartment = async (department_id: any) => {
    setValue('department_id', department_id)
  }

  const modalPop = async () => {
    if (isEdit) {
      handleUpdateConsultant(
        AllData,
        EmployeeIdData,
        weekDayDataEntry,
        AllBranchesList,
        AllDepartmentsList,
        AllRelationManagerList,
        SelectedRelationList,
        // AllSaleContactList,
        monthDayDataEntry,
        ApproverBusinessList,
        SelectedApproverBusinessList
      )
      setList(false)
    }
    setList(false)
  }

  const handleProjectStartDate = async (date: any) => {
    setJoiningDate(date)
    // setStartDate(date)
    setValue('joining_date', date)
  }

  const handleLeavingDate = async (date: any) => {
    setLeavingDate(date)
    setValue('leaving_date', date)
  }
  const modalActiveInactiveClosePop = () => {
    setActiveInactive(false)
    setConsultantStatus('Active')
    setValue('record_type_status', 'Active')
  }
  const handleConsultantStatus = async (consultant_status: any) => {
    if (isEdit && consultant_status == 'Inactive') {
      const activeProjects: any = await Apiservices.get_all_active_project_by_user_id({
        user_id: userIdData11,
      })
      if (activeProjects.data.isError == false) {
        if (activeProjects?.data?.data?.length > 0) {
          setActiveInactive(true)
        } else {
          setActiveInactive(false)
        }
      }
    }
    setConsultantStatus(consultant_status)
    setLeavingDate(null)
    setValue('record_type_status', consultant_status)
    setValue('leaving_date', '')
  }

  function closeModalHandler() {
    setViewShow(false)
  }

  function closeModalHandler1() {
    setViewShow1(false)
  }

  const setConsultant = async (passed_status: any) => {
    setPropsConsultant(passed_status)
    setShowBackDrop(true)

    // setButtonAdpDisable(false)
    setViewShow1(false)
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.adpAssociateIdData({workerid: checkAdpData})
    setShowBackDrop(false)

    if (employeeValid.data.placement_api_response.message == 'No Record with the given workerid') {
      if (adpData == 'changed Data') {
        setValue('first_name', '')
        setValue('display_name', '')
        setValue('last_name', '')
        setValue('record_type_status', '')
        setValue('joining_date', null)
        ErrorToast('ECDB Id Exist in Time Tracker')
        setAdpMessage(true)
        setViewShow(true)
        setAdpData('exist')
      }
      ErrorToast('No Record with the given workerid')
    } else {
      setAdpMessage(false)
      setShowButton(true)
      SuccessToast('ADP Details Fetched SuccessFully')
      setAdpData('changed Data')
    }
    setAdpMessage(employeeValid.data.placement_api_response.message)
    setShowBackDrop(false)
    setValidateAdpByConsultantName(true)
    setAdpConsultantname(
      `${employeeValid.data.data[0].LegalFamilyName} ${employeeValid.data.data[0].LegalGivenName}`
    )

    setAdpFirstName(employeeValid.data.data[0].LegalFamilyName)
    setAdpLastName(employeeValid.data.data[0].LegalGivenName)
    setAdpStatus(employeeValid.data.data[0].workerStatusCodeValue)
    setAdpDate(employeeValid.data.data[0].OriginalHireDate)
    setValue('first_name', employeeValid.data.data[0].LegalFamilyName)
    setValue('display_name', employeeValid.data.data[0].LegalFormattedName)
    setValue('last_name', employeeValid.data.data[0].LegalGivenName)
    setValue('record_type_status', employeeValid.data.data[0].workerStatusCodeValue)
    setValue('joining_date', employeeValid.data.data[0].OriginalHireDate)
    setAdpValidateFieldsDisabled(true)
    let start_date_format: any = new Date(employeeValid.data.data[0].OriginalHireDate)
    setJoiningDate(start_date_format)
    // const value = e.target.value
    // const regex = /^[0-9a-zA-Z(\-)]+$/ //this will admit letters, numbers and dashes
    // if (value.match(regex) || value === '') {
    //   setADPID(value)
    // }
    setShowBackDrop(false)
  }

  const handleViewEdit = () => {
    navigate('/masters/manageconsultants/updateconsultant')
    setIsView(false)
    setCanFlag(false)
    setProspectiveFieldDisable(false)
  }

  const handleRelation = (selected: any) => {
    if (selected.length > 0) {
      const mapFromColors: any = new Map(selected.map((c: any) => [c.id, c]))
      const uniqueColors = [...mapFromColors.values()]
      setRelation(uniqueColors)
    } else {
      setRelation(selected)
    }
  }

  const handleSale = (selected: any) => {
    if (selected.length > 0) {
      const mapFromColors: any = new Map(selected.map((c: any) => [c.userId, c]))
      const uniqueColors = [...mapFromColors.values()]
      setSale(uniqueColors)
    } else {
      setSale(selected)
    }
  }

  const handleApprovers = (selected: any) => {
    if (selected.length > 0) {
      const mapFromColors: any = new Map(selected.map((c: any) => [c.id, c]))
      const uniqueColors = [...mapFromColors.values()]
      setSelectConsultant(uniqueColors)
    } else {
      setSelectConsultant(selected)
    }
  }
  console.log(ViewEdit, typeof ViewEdit, 'ViewEdit')
  const handleTabs = async (keys1: any) => {
    setKey(keys1)
    if (keys1 == 'project') {
      setShowBackDrop(true)
      const response1: any = await Apiservices.projectUserData1({user_id: userUpdateId}) //projects2/sync_projects_by_user_id
      setMainTableData(response1.data.data)
      const res: any = await Apiservices.updateUserEmpId({user_id: userUpdateId}) //projects2/get_projects_by_user_id
      setProjectDetails(res?.data?.data)

      setMainTableData(res.data.data)
      // setUpdateTableData(res.data.data[0].project_vendors)
      setResponseData1(res.data.message)
      setShowBackDrop(false)
    }
  }
  return (
    <>
      {list ? (
        <ConsultantPop
          customermessage={customermessage}
          close={modalPop}
          cancel={handleCancel}
          closeModal={list}
          btnValue={btnValue}
        />
      ) : (
        ''
      )}
      {ActiveInactive && (
        <ConsultantActiveInactivePop modalActiveInactiveClosePop={modalActiveInactiveClosePop} />
      )}
      {viewshow && (
        <AdpPopup
          onCancel={closeModalHandler}
          // cancel={handleCancel}
        />
      )}
      {QBConsultant && (
        <ConsultantQBPop ConsultantQBOk={ConsultantQBOk} ConsultantQBClose={ConsultantQBClose} />
      )}
      {viewshow1 && (
        <AdpAssociatePopup
          setConsultant={setConsultant}
          checkAdpData={checkAdpData}
          setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler1}
          // cancel={handleCancel}
        />
      )}

      <BackDrop showBackDrop={showBackDrop} />
      {isView ? (
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'View Consultant Details'})}
        </PageTitle>
      ) : isEdit ? (
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'Edit Consultant Details'})}
        </PageTitle>
      ) : (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADD CONSULTANT'})}</PageTitle>
      )}

      <Toolbar1 setIsLogin={setIsLogin} isLogin={isLogin} />
      {loading ? (
        <>
          <Tabs
            id='controlled-tab-example'
            activeKey={key}
            // onSelect={(k) => setKey(k)}
            onSelect={(k) => handleTabs(k)}
            className='mb-3'
          >
            <Tab eventKey='personal' title='Personal Details'>
              <>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                  {!qb_disable_fields && (
                    <div className='card card-custom card-flush mb-6'>
                      <div className='card-body py-5'>
                        <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Search Consultant</h3>
                        <div className='col-md-3 col-xs-12'></div>
                        <div className='row gy-4'>
                          {!qb_disable_fields && (
                            <div className='col-xs-12 col-md-3 '>
                              <label className='form-label'>Consultant Type *</label>
                              <select
                                className={clsx(
                                  'form-select',
                                  errors.employee_type ? 'is-invalid' : ''
                                )}
                                {...register('employee_type', {
                                  required: true,
                                  onChange: (e) => {
                                    handleConsultantType(e.target.value)
                                    setValue('employee_type', e.target.value)
                                  },
                                })}
                                // className='form-select'
                                aria-label='Default select example'
                              >
                                <option value=''>Select Consultant Type</option>
                                <option value='W2'>W2</option>
                                <option value='C2C'>C2C</option>
                                <option value='India Offshore'>India Offshore</option>
                                <option value='Internal'>Internal</option>
                                <option value='1099'>1099</option>
                              </select>
                              {errors.employee_type &&
                                errors.employee_type.type === 'required' &&
                                !getValues('employee_type') && (
                                  <p className='error text-danger mb-0'>
                                    Consultant Type is required.
                                  </p>
                                )}
                            </div>
                          )}

                          {!isView && (
                            <>
                              {!qb_disable_fields && (
                                <>
                                  {selectedItem !== 'Internal' && (
                                    <>
                                      <div className='col-xs-12 col-md-6'>
                                        <label className='form-label '>Search Candidate </label>
                                        <div className='input-group mb-3'>
                                          <Typeahead
                                            id='basic-typeahead-single focusTypeHead'
                                            disabled={selectedItem == '' ? true : false}
                                            {...register('search_candidate')}
                                            onChange={(e) => onchnagehandler1(e)}
                                            onInputChange={(e) => onChangeHandler(e, 'Not')}
                                            onBlur={(e) => {
                                              onChangeHandler(e.target.defaultValue, 'blur')
                                            }}
                                            options={suggestions}
                                            placeholder='Search with name,email,phone'
                                            selected={singleSelections}
                                            labelKey={(suggestions: any) =>
                                              `${user(
                                                suggestions.name
                                              )}                                                                                                                                                                       -${
                                                suggestions.email ? suggestions.email : ''
                                              }  ${
                                                suggestions.phone == null ? '' : suggestions.phone
                                              }`
                                            }
                                            onKeyDown={handleKeyPress}
                                            inputProps={{required: true}}
                                            ref={typeaheadRef6}
                                          >
                                            {({onClear, selected}) => (
                                              // labelKey={(suggestions: any) =>
                                              //   suggestions.name
                                              //     ? suggestions.name
                                              //       ? suggestions.name
                                              //       : ''
                                              //     : suggestions.email
                                              //     ? suggestions.email
                                              //       ? suggestions.email
                                              //       : ''
                                              //     : suggestions.phone
                                              //     ? suggestions.phone
                                              //       ? suggestions.phone
                                              //       : ''
                                              //     : ''
                                              // }

                                              <>
                                                <div className='rbt-aux'>
                                                  <>
                                                    {!!selected.length && (
                                                      <>
                                                        <ClearButton onClick={onClear} />
                                                      </>
                                                    )}
                                                  </>
                                                </div>
                                              </>
                                            )}
                                          </Typeahead>
                                          <button
                                            className='btn btn-outline btn-outline-solid btn-outline-secondary btn-active-light-primary'
                                            type='button'
                                            onClick={(e) =>
                                              onChangeHandler(
                                                getValues('search_candidate'),
                                                'enter'
                                              )
                                            }
                                          >
                                            <i className='bi bi-search '></i>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {isView && (
                    <>
                      {numArray?.includes('hr') || numArray?.includes('relationshipmanager') ? (
                        ''
                      ) : (
                        <>
                          <div className='d-flex justify-content-end pb-2'>
                            <div>
                              <button
                                className='btn btn-primary form-group  py-2 '
                                type='button'
                                onClick={() => handleViewEdit()}
                                aria-disabled='true'
                              >
                                Edit Employee
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className='card card-custom card-flush mb-6'>
                    <div className='card-body py-5'>
                      {/* <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Personal Details</h3> */}
                      <div className='row gy-4'>
                        <div className='col-md-3 col-xs-12 d-none'>
                          <label className='form-label '>Id</label>
                          <input
                            type='text'
                            disabled={true}
                            {...register('id')}
                            className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                          />
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Prefix *</label>
                          <select
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            className={clsx('form-select', errors.prefix ? 'is-invalid' : '')}
                            {...register('prefix')}
                          >
                            <option value=''>Select</option>
                            <option value='1'>Mr</option>
                            <option value='2'>Ms</option>
                            <option value='3'>Mrs</option>
                          </select>
                          <div className='invalid-feedback'>{errors.prefix?.message}</div>
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>First Name *</label>
                          <input
                            type='text'
                            className='form-control fs-6 text-capitalize'
                            placeholder='First Name'
                            disabled={
                              (isEdit || selectedItem == ''
                                ? true
                                : false || canFlag || AdpValidateFieldsDisabled) ||
                              adpData === 'changed Data' ||
                              isView
                            }
                            {...register('first_name', {
                              required: true,
                            })}
                            onKeyPress={(e) => {
                              if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                              } else e.preventDefault()
                            }}
                          />
                          {/* <div className='invalid-feedback'>{errors.first_name?.message}</div> */}
                          {errors.first_name && errors.first_name.type === 'required' && (
                            <p className='error text-danger mb-0'>First Name is required.</p>
                          )}
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Last Name *</label>
                          <input
                            {...register('last_name')}
                            placeholder='Last Name'
                            // disabled={adpData === 'changed Data' || isView}
                            disabled={
                              (isEdit || selectedItem == ''
                                ? true
                                : false || canFlag || AdpValidateFieldsDisabled) ||
                              adpData === 'changed Data' ||
                              isView
                            }
                            type='text'
                            onKeyPress={(e) => {
                              if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                              } else e.preventDefault()
                            }}
                            className={`form-control text-capitalize ${
                              errors.last_name ? 'is-invalid' : ''
                            }`}
                          />
                          <div className='invalid-feedback'>{errors.last_name?.message}</div>
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Display Name *</label>
                          <input
                            type='text'
                            className='form-control fs-6 text-capitalize'
                            placeholder='Display Name'
                            disabled={isView}
                            {...register('display_name', {
                              required: true,
                            })}
                            // onKeyPress={(e) => {
                            //   if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                            //   } else e.preventDefault()
                            // }}
                          />

                          {errors.display_name && errors.display_name.type === 'required' && (
                            <p className='error text-danger mb-0'>Display Name is required.</p>
                          )}
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label'>Email *</label>
                          <input
                            type='email'
                            {...register('email')}
                            // disabled={isView}
                            disabled={
                              (selectedItem == ''
                                ? true
                                : false || canFlag || resEcdbDetailsFromLocaldb) || isView
                            }
                            placeholder='Email'
                            // onChange={handleChange5}
                            onBlur={(e) => {
                              if (e.target.value !== '') {
                                {
                                  isEdit
                                    ? onClickUpdate(e.target.value)
                                    : onClickSubmit(e.target.value)
                                }
                              }
                            }}
                            // ref={register({ required: true, validate: handleEmailValidation })}
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          />
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: 'red',
                            }}
                          >
                            {emailError}
                          </span>
                          {errors.email && errors.email.type === 'required' && (
                            <p className='error text-danger mb-0'>Email is required.</p>
                          )}
                          {/* <div className='invalid-feedback'>{errors.email?.message}</div> */}
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Phone Number *</label>
                          <input
                            {...register('phone_number')}
                            type='text'
                            placeholder='Phone Number'
                            minLength={10}
                            maxLength={15}
                            // value={numberData}
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            // onChange={(e) => checkInput(e)}
                            onKeyPress={(e) => {
                              if (new RegExp(/[0-9+-]/i).test(e.key)) {
                              } else e.preventDefault()
                            }}
                            className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                          />
                          <div className='invalid-feedback'>{errors.phone_number?.message}</div>
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Gender *</label>
                          <select
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            className={clsx('form-select', errors.gender ? 'is-invalid' : '')}
                            {...register('gender')}
                          >
                            <option value=''>Select</option>
                            <option value='MALE'>Male</option>
                            <option value='FEMALE'>Female</option>
                          </select>
                          <div className='invalid-feedback'>{errors.gender?.message}</div>
                        </div>
                        {/* {selectedItem == "W2" && (
                    <> */}

                        {selectedItem == 'W2' && (
                          <>
                            {disableAdp && (
                              <>
                                {/* <div className='col-12'></div> */}
                                <div className='col-md-3 col-xs-12'>
                                  <label className='form-label '> ADP Associate ID * </label>
                                  <input
                                    {...register('adp_associate_id')}
                                    type='text'
                                    placeholder='ADP Associate ID'
                                    maxLength={15}
                                    // value={adpId}
                                    onChange={(e) => checkADP(e, 'Not')}
                                    onBlur={(e) => {
                                      checkADP(e, 'Blur')
                                    }}
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                                      } else e.preventDefault()
                                    }}
                                    // disabled={isView}
                                    disabled={canFlag || isView}
                                    required
                                    className={`form-control ${
                                      errors.adp_associate_id ? 'is-invalid' : ''
                                    }`}
                                  />

                                  <div className='invalid-feedback'>
                                    {errors.adp_associate_id?.message}
                                  </div>
                                </div>

                                {!isView && (
                                  <>
                                    {/* <div className='col-auto '> */}
                                    <div className='col-md-3 col-xs-12'>
                                      <button
                                        className='btn btn-light-primary form-group  mt-8'
                                        type='button'
                                        onClick={checkADP1}
                                        disabled={showButton}
                                        aria-disabled='true'
                                      >
                                        Validate ADP
                                      </button>
                                    </div>

                                    {/* <div className='col'> */}
                                    <div className='col-md-3 col-xs-12'>
                                      <label className='form-label '>ADP Validated (On/Off)</label>
                                      <div
                                        // onChange={handleDirectEmployeeLevel}
                                        className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                                      >
                                        <input
                                          className='form-check-input form-control fs-6 my-2'
                                          // {...register('validate_adp_employee')}
                                          type='checkbox'
                                          role='switch'
                                          id='flexSwitchCheckDefault'
                                          checked={AdpAssociateIdDataMessage}
                                        />
                                        <span className='ps-2'>
                                          {AdpAssociateIdDataMessage ? 'On ' : 'Off'}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className='col-md-3 col-xs-12'>
                                  <label className='form-label '>Company Code *</label>
                                  <input
                                    type='text'
                                    className={`form-control fs-6 ${
                                      errors.company_code ? 'is-invalid' : ''
                                    }`}
                                    placeholder='Company Code'
                                    disabled={canFlag || isView}
                                    {...register('company_code', {})}
                                    required
                                  />
                                  <div className='invalid-feedback'>
                                    {errors.company_code?.message}
                                  </div>
                                </div>
                                <div className='col-md-3 col-xs-12'>
                                  <label className='form-label '>File Number *</label>
                                  <input
                                    type='text'
                                    className={`form-control fs-6 ${
                                      errors.file_number ? 'is-invalid' : ''
                                    }`}
                                    placeholder='File Number'
                                    disabled={canFlag || isView}
                                    {...register('file_number', {})}
                                    required
                                  />
                                  <div className='invalid-feedback'>
                                    {errors.file_number?.message}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {/* const onlyDigits = e.target.value.replace(/\D/g, '') */}
                      </div>
                    </div>
                  </div>
                  <div className='card card-custom card-flush mb-6'>
                    <div className='card-body py-5'>
                      <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                        Organization Details{' '}
                      </h3>
                      <div className='row gy-4'>
                        {isEdit && (
                          <div className='col-md-3 col-xs-12'>
                            <label className='form-label '>Consultant ID </label>
                            <input
                              type='text'
                              disabled={true}
                              {...register('employee_id')}
                              className={`form-control`}
                            />
                          </div>
                        )}

                        {selectedItem == 'Internal'
                          ? ''
                          : (numArray?.includes('admin') ||
                              numArray?.includes('timesheetadmin')) && (
                              <>
                                <div className='col-md-3 col-xs-12'>
                                  <label className='form-label '>
                                    Prospective ID
                                    {/* {selectedItem != 'Internal' && '*'} */}
                                  </label>
                                  <input
                                    type='text'
                                    disabled={
                                      numArray?.includes('admin')
                                        ? ProspectiveFieldDisable
                                          ? true
                                          : false
                                        : true
                                    }
                                    {...register('prospective_user_id', {
                                      // required: true,
                                      onChange: (e) => {
                                        handleProspectiveUserId(e.target.value)
                                      },
                                    })}
                                    className='form-control fs-6'
                                    // className={`form-control  ${
                                    //   errors.prospective_user_id ? 'is-invalid' : ''
                                    // }`}
                                  />
                                  {/* <div className='invalid-feedback'>
                                {errors.prospective_user_id?.message}
                              </div> */}
                                </div>
                              </>
                            )}
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Date Of Joining (MM/DD/YYYY) *</label>
                          <DatePickerIcon
                            className='form-control fs-6'
                            {...register('joining_date', {
                              required: true,
                            })}
                            // disabled={
                            //   (isEdit || selectedItem == ''
                            //     ? true
                            //     : false || canFlag || AdpValidateFieldsDisabled) ||
                            //   // adpData === 'changed Data' ||
                            //   isView
                            // }
                            disabled={
                              (selectedItem == ''
                                ? true
                                : false ||
                                  canFlag ||
                                  AdpValidateFieldsDisabled ||
                                  JoiningFieldsDisabled) ||
                              // adpData === 'changed Data' ||
                              isView
                            }
                            selected={JoiningDate}
                            dateFormat='MM/dd/yyyy'
                            handleDate={(date: any) => {
                              handleProjectStartDate(date)
                              // setStartDate(date)
                              // setValue('start_date', date)
                            }}
                            placeholderText='Select date'
                          />

                          <div>
                            {errors.joining_date &&
                              errors.joining_date.type === 'required' &&
                              !getValues('joining_date') && (
                                <p className='error text-danger mb-0'>
                                  Date Of Joining is required.
                                </p>
                              )}
                          </div>
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Consultant Status</label>
                          <select
                            // disabled={adpData === 'changed Data' || isView}
                            disabled={
                              //   (isEdit || selectedItem == '' ? true : false || canFlag) ||
                              //   adpData === 'changed Data' ||
                              isView
                            }
                            defaultValue={'Active'}
                            // {...register('record_type_status')}
                            {...register('record_type_status', {
                              // required: true,
                              onChange: (e) => {
                                handleConsultantStatus(e.target.value)
                                setValue('record_type_status', e.target.value)
                              },
                            })}
                            className={clsx(
                              'form-select',
                              errors.record_type_status ? 'is-invalid' : ''
                            )}
                          >
                            {/* <option value=''>Select</option> */}
                            <option value='Active'>Active</option>
                            <option value='Inactive'>InActive</option>
                          </select>

                          <div className='invalid-feedback'>
                            {errors.record_type_status?.message}
                          </div>
                        </div>
                        {(consultantstatus == 'Inactive' || consultantstatus == 'InActive') && (
                          <div className='col-md-3 col-xs-12'>
                            <label className='form-label '>Date Of Leaving (MM/DD/YYYY) *</label>
                            <DatePickerIcon
                              className='form-control fs-6'
                              {...register('leaving_date', {
                                required: true,
                              })}
                              selected={LeavingDate}
                              dateFormat='MM/dd/yyyy'
                              handleDate={(date: any) => {
                                handleLeavingDate(date)
                              }}
                              min={JoiningDate ? JoiningDate : ''}
                              placeholderText='Select a date'
                            />

                            <div>
                              {errors.leaving_date &&
                                errors.leaving_date.type === 'required' &&
                                !getValues('leaving_date') && (
                                  <p className='error text-danger mb-0'>
                                    Leaving Date is required.
                                  </p>
                                )}
                            </div>
                          </div>
                        )}

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Send Reminder Email</label>
                          <select
                            // disabled={adpData === "changed Data" || isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            {...register('email_notifications')}
                            className={clsx(
                              'form-select',
                              errors.email_notifications ? 'is-invalid' : ''
                            )}
                            defaultValue={'true'}
                          >
                            {/* <option value=''>Select</option> */}
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                          <div className='invalid-feedback'>
                            {errors.email_notifications?.message}
                          </div>
                        </div>
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Business Unit *</label>
                          <select
                            // disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            disabled={Edit || isView}
                            {...register('business_unit_id', {
                              onChange: (e) => {
                                handleBusinessUnit(e.target.value)
                              },
                            })}
                            className={clsx(
                              'form-select',
                              errors.business_unit_id ? 'is-invalid' : ''
                            )}
                            defaultValue={'true'}
                          >
                            <option value=''>Select Business Unit</option>
                            {BusinessUnitsList &&
                              BusinessUnitsList.length > 0 &&
                              BusinessUnitsList.map((option: any) => {
                                return (
                                  <option
                                    value={option.business_unit_id}
                                    className='text-capitalize'
                                  >
                                    {option.name}
                                  </option>
                                )
                              })}
                          </select>
                          {errors.business_unit_id &&
                            errors.business_unit_id.type === 'required' &&
                            !getValues('business_unit_id') && (
                              <p className='error text-danger mb-0'>Business Unit is required.</p>
                            )}
                          {/* <div className='invalid-feedback'>{errors.business_unit_id?.message}</div> */}
                        </div>
                        {getValues('business_unit_id') && ApproverUnitsList?.length > 0 && (
                          <div className='col-md-3 col-xs-12'>
                            <label className='form-label '> Approver(s) *</label>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              onChange={(selected: any) => {
                                handleApprovers(selected)
                                // setSelectConsultant(selected)
                                // Keep the menu open when making multiple selections.
                                typeaheadRef3.current.toggleMenu()
                              }}
                              disabled={isView}
                              options={ApproverUnitsList}
                              placeholder='Select Approver(s)'
                              ref={typeaheadRef3}
                              selected={selectConsultant}
                              labelKey={(ApproverUnitsList: any) =>
                                user(ApproverUnitsList.full_name)
                              }
                              inputProps={{
                                // required: true
                                required:
                                  selectConsultant == null ||
                                  selectConsultant == [] ||
                                  selectConsultant == '' ||
                                  selectConsultant?.length == 0 ||
                                  selectConsultant == undefined
                                    ? true
                                    : false,
                              }}
                            >
                              {/* {({onClear, selected}) => (
                                <div className='rbt-aux'>
                                  {!!selected.length && <ClearButton onClick={onClear} />}
                                </div>
                              )} */}
                            </Typeahead>
                          </div>
                        )}

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Branch </label>
                          <select
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            {...register('branch_id', {
                              onChange: (e) => {
                                handleBranch(e.target.value)
                              },
                            })}
                            className={clsx('form-select', errors.branch_id ? 'is-invalid' : '')}
                            // defaultValue={'true'}
                          >
                            <option value=''>Select Branch</option>
                            {BranchList &&
                              BranchList.length > 0 &&
                              BranchList.map((option: any) => {
                                return <option value={option.ecdb_branch_id}>{option.name}</option>
                              })}
                          </select>
                          {/* {errors.branch_id &&
                            errors.branch_id.type === 'required' &&
                            !getValues('branch_id') && (
                              <p className='error text-danger mb-0'>Branch is required.</p>
                            )} */}
                        </div>
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Department *</label>
                          <select
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            {...register('department_id', {
                              onChange: (e) => {
                                handleDepartment(e.target.value)
                              },
                            })}
                            className={clsx(
                              'form-select',
                              errors.department_id ? 'is-invalid' : ''
                            )}
                            // defaultValue={'true'}
                          >
                            <option value=''>Select Department</option>
                            {DepartmentList &&
                              DepartmentList.length > 0 &&
                              DepartmentList.map((option: any) => {
                                return (
                                  <option value={option.ecdb_department_id}>{option.name}</option>
                                )
                              })}
                          </select>
                          {errors.department_id &&
                            errors.department_id.type === 'required' &&
                            !getValues('department_id') && (
                              <p className='error text-danger mb-0'>Department is required.</p>
                            )}
                          {/* <div className='invalid-feedback'>{errors.department_id?.message}</div> */}
                        </div>
                        {getValues('business_unit_id') && RelationDetails?.length > 0 && (
                          <div className='col-md-3 col-xs-12'>
                            <label className='form-label '>Relationship Manager </label>
                            {/* <select
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            {...register('relation_manager', {
                              onChange: (e) => {
                                handleRelManager(e.target.value)
                              },
                            })}
                            className={clsx(
                              'form-select',
                              errors.relation_manager ? 'is-invalid' : ''
                            )}
                            defaultValue={'true'}
                          >
                            <option value=''>Select Relationship Manager</option>
                            {InternalUserDetails &&
                              InternalUserDetails.length > 0 &&
                              InternalUserDetails.map((option: any) => {
                                return (
                                  <option value={`${option.userId}`} className='text-capitalize'>
                                    {`${option.firstName} ${option.lastName}`}
                                  </option>
                                )
                              })}
                          </select> */}
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              onChange={(selected: any) => {
                                handleRelation(selected)
                                // setRelation(selected)
                                // Keep the menu open when making multiple selections.
                                typeaheadRef2.current.toggleMenu()
                              }}
                              // disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                              disabled={isView}
                              options={RelationDetails}
                              placeholder='Select Relationship Manager'
                              ref={typeaheadRef2}
                              selected={Relation}
                              labelKey={(RelationDetails: any) =>
                                `${user(RelationDetails.first_name)} ${user(
                                  RelationDetails.last_name
                                )}`
                              }
                            ></Typeahead>
                          </div>
                        )}
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Sales Contact </label>
                          {/* <select
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            {...register('sales_contact', {
                              onChange: (e) => {
                                handleSalesManager(e.target.value)
                              },
                            })}
                            className={clsx(
                              'form-select',
                              errors.sales_contact ? 'is-invalid' : ''
                            )}
                            defaultValue={'true'}
                          >
                            <option value=''>Select Sales Contact</option>
                            {InternalUserDetails &&
                              InternalUserDetails.length > 0 &&
                              InternalUserDetails.map((option: any) => {
                                return (
                                  <option value={`${option.userId}`} className='text-capitalize'>
                                    {`${option.firstName} ${option.lastName}`}
                                  </option>
                                )
                              })}
                          </select> */}

                          <Typeahead
                            multiple
                            id='keep-menu-open'
                            onChange={(selected: any) => {
                              handleSale(selected)
                              // setSale(selected)
                              // Keep the menu open when making multiple selections.
                              typeaheadRef4.current.toggleMenu()
                            }}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            options={InternalUserDetails}
                            placeholder='Select Sales Contact'
                            ref={typeaheadRef4}
                            selected={sale}
                            labelKey={(InternalUserDetails: any) =>
                              `${InternalUserDetails.firstName} ${InternalUserDetails.lastName}`
                            }
                          ></Typeahead>
                        </div>
                      </div>
                    </div>
                  </div>
                  {localStorage.getItem('refresh_Token') == 'false' && (
                    <>
                      {(numArray?.includes('superadmin') ||
                        numArray?.includes('admin') ||
                        numArray?.includes('accounts')) && (
                        <>
                          {disableQuickBooks && (
                            <>
                              {qb_disable_fields && (
                                <>
                                  {IndividualBU && (
                                    <>
                                      {transactionDisabled && ( //if transaction button checks
                                        <>
                                          <div className='card card-custom card-flush mb-6'>
                                            <div className='card-body py-5'>
                                              <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                                                QB Details{' '}
                                              </h3>
                                              <div className='row gy-4'>
                                                <div className='col-md-3 col-xs-12'>
                                                  <label className='form-label '>
                                                    QB Consultant Name{' '}
                                                  </label>
                                                  <input
                                                    // disabled={true}
                                                    disabled={disableQBClient}
                                                    type='text'
                                                    {...register('qb_employee_name')}
                                                    className={`form-control ${
                                                      errors.qb_employee_name ? 'is-invalid' : ''
                                                    }`}
                                                  />
                                                  <div className='invalid-feedback'>
                                                    {errors.qb_employee_name?.message}
                                                  </div>
                                                </div>
                                                {clientopen && (
                                                  <div className='col-xs-12 col-md-3 '>
                                                    <label className='form-label '>
                                                      QB Consultant ID*{' '}
                                                    </label>
                                                    <input
                                                      type='text'
                                                      className='form-control fs-6'
                                                      disabled={true}
                                                      {...register('qb_employee_id')}
                                                    />
                                                  </div>
                                                )}
                                                {isView == false && (
                                                  <>
                                                    {getValues('qb_employee_id') ? (
                                                      <></>
                                                    ) : (
                                                      <div className='col-xs-12 col-md-3 '>
                                                        <button
                                                          className='btn btn-light-primary form-group  mt-8'
                                                          type='button'
                                                          disabled={!disableQuickBooks}
                                                          onClick={() =>
                                                            handleCustomerName(clientname, '')
                                                          }
                                                          aria-disabled='true'
                                                        >
                                                          Get QB Consultant ID
                                                        </button>
                                                      </div>
                                                    )}
                                                    {/* </>
                          )} */}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {DisableNetSuite && (
                    <>
                      {qb_disable_fields && (
                        <>
                          <div className='card card-custom card-flush mb-6'>
                            <div className='card-body py-5'>
                              <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                                NetSuite Details{' '}
                              </h3>
                              <div className='row gy-4'>
                                <div className='col-md-3 col-xs-12'>
                                  <label className='form-label '>NetSuite Consultant Name </label>
                                  <input
                                    disabled={true}
                                    type='text'
                                    {...register('netSuite_employee_name')}
                                    className={`form-control ${
                                      errors.netSuite_employee_name ? 'is-invalid' : ''
                                    }`}
                                  />
                                  <div className='invalid-feedback'>
                                    {errors.netSuite_employee_name?.message}
                                  </div>
                                </div>
                                {netopen && (
                                  <div className='col-xs-12 col-md-3 '>
                                    <label className='form-label '>NetSuite Consultant ID* </label>
                                    <input
                                      type='text'
                                      className='form-control fs-6'
                                      disabled={true}
                                      {...register('netSuite_employee_id')}
                                    />
                                  </div>
                                )}
                                {isView == false && (
                                  <>
                                    {getValues('netSuite_employee_id') ? (
                                      <></>
                                    ) : (
                                      <div className='col-xs-12 col-md-3 '>
                                        <button
                                          className='btn btn-light-primary form-group  mt-8'
                                          type='button'
                                          disabled={!DisableNetSuite}
                                          onClick={() => handleCustomerName1(clientname)}
                                          aria-disabled='true'
                                        >
                                          Get NetSuite Consultant ID
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className='card card-custom card-flush mb-6'>
                    <div className='card-body py-5'>
                      <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Address Details </h3>
                      <div className='row gy-4'>
                        {countryData !== null && (
                          <div className='col-xs-12 col-md-3 '>
                            <label className='form-label '>Work Country * </label>
                            <Typeahead
                              id='basic-typeahead-single'
                              // disabled={isView}
                              disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                              // {...register('work_country')}
                              onChange={(e) => selectedCountries(e)}
                              onInputChange={(e) => handleCountries(e)}
                              placeholder='Country'
                              options={country}
                              selected={countries}
                              labelKey={(country: any) => country.name}
                              inputProps={{required: true}}
                            >
                              {({onClear, selected}) => (
                                <div className='rbt-aux'>
                                  {!!selected.length && <ClearButton onClick={onClear} />}
                                </div>
                              )}
                            </Typeahead>

                            <div className='invalid-feedback'>{errors.work_country?.message}</div>
                          </div>
                        )}
                        {countryData == null && (
                          <div className='col-xs-12 col-md-3 '>
                            <label className='form-label '>Work Country * </label>
                            <Typeahead
                              id='basic-typeahead-single'
                              disabled={isView}
                              // {...register('work_country')}
                              onChange={(e) => selectedCountries(e)}
                              onInputChange={(e) => handleCountries(e)}
                              placeholder='Country'
                              options={country}
                              // selected={countries}
                              labelKey={(country: any) => country.name}
                              inputProps={{required: true}}
                            >
                              {({onClear, selected}) => (
                                <div className='rbt-aux'>
                                  {!!selected.length && <ClearButton onClick={onClear} />}
                                </div>
                              )}
                            </Typeahead>

                            <div className='invalid-feedback'>{errors.work_country?.message}</div>
                          </div>
                        )}
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>State *</label>
                          <Typeahead
                            id='basic-typeahead-single'
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            onChange={(e) => selectedStates(e)}
                            onInputChange={(e) => handleStates(e)}
                            options={stateName}
                            placeholder='State'
                            selected={statesId}
                            labelKey={(stateName: any) => stateName.name}
                            inputProps={{required: true}}
                          >
                            {({onClear, selected}) => (
                              <div className='rbt-aux'>
                                {!!selected.length && <ClearButton onClick={onClear} />}
                              </div>
                            )}
                          </Typeahead>
                        </div>
                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>City *</label>
                          <input
                            type='text'
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            placeholder='City'
                            minLength={3}
                            {...register('work_city')}
                            className={`form-control text-capitalize ${
                              errors.work_city ? 'is-invalid' : ''
                            }`}
                          ></input>
                          <div className='invalid-feedback'>{errors.work_city?.message}</div>
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>
                            Visa Status{' '}
                            {(selectedItem == 'W2' || selectedItem == 'Internal') && '*'}
                          </label>
                          <select
                            className={clsx('form-select', errors.visa_status ? 'is-invalid' : '')}
                            {...register('visa_status')}
                            id='visa_status'
                            // disabled={isView}
                            disabled={
                              (selectedItem == '' ? true : false || canFlag) ||
                              isView ||
                              visaDisabled
                            }
                            onChange={workData}
                            // required
                          >
                            {workAuthorizationData.map((option: any) => {
                              return (
                                <option value={option.workAuthorizationName}>
                                  {option.workAuthorizationName}
                                  {/* {option.workAuthorizationId} */}
                                </option>
                              )
                            })}
                          </select>
                          <div className='invalid-feedback'>{errors.visa_status?.message}</div>
                          {/* {(selectedItem == 'W2' || selectedItem == 'Internal') && (
                            <>
                              {errors.visa_status && errors.visa_status.type === 'required' && (
                                <p className='error text-danger mb-0'>Visa Status is required.</p>
                              )}
                            </>
                          )} */}
                        </div>

                        <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Zip Code *</label>
                          <input
                            type='text'
                            {...register('zipcode')}
                            // disabled={isView}
                            disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                            placeholder='Zip Code'
                            minLength={4}
                            maxLength={6}
                            onKeyPress={(e) => {
                              if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                              } else e.preventDefault()
                            }}
                            // value={zip}
                            // onChange={(e) => checkZip(e)}
                            className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                          ></input>
                          <div className='invalid-feedback'>{errors.zipcode?.message}</div>
                        </div>
                        <div className='py-1 '>
                          <div className='row'>
                            <div className='col'>
                              <label className='form-label'>Permanent Address</label>
                              <textarea
                                // disabled={isView}
                                disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                                minLength={3}
                                className={`form-control ${
                                  errors.permanent_address ? 'is-invalid' : ''
                                }`}
                                {...register('permanent_address')}
                                placeholder=' Permanent Address'
                                rows={3}
                              ></textarea>
                              {/* <div className='invalid-feedback'>{errors.permanent_address?.message}</div> */}
                            </div>
                            <div className='col-md-6 col-xs-12'>
                              <label className='form-label '>Work Address</label>
                              <textarea
                                className={`form-control ${
                                  errors.work_street_address ? 'is-invalid' : ''
                                }`}
                                placeholder='Work Address'
                                // disabled={isView}
                                disabled={(selectedItem == '' ? true : false || canFlag) || isView}
                                minLength={3}
                                // maxLength={200}
                                rows={3}
                                {...register('work_street_address')}
                              ></textarea>

                              <div className='invalid-feedback'>
                                {errors.work_street_address?.message}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <ToastContainer /> */}
                  </div>
                  {isView ? (
                    <h1></h1>
                  ) : (
                    <div className='d-flex gap-3 justify-content-end'>
                      {disableAdp &&
                        disableEmployeeLevelAdp && ( //true true
                          <>
                            {!isEdit && (
                              <>
                                {insertDisable == false && (
                                  <>
                                    {selectedItem == 'W2' && (
                                      <div>
                                        <button
                                          className='btn btn-sm btn-primary'
                                          disabled={
                                            resProspDetailsFromLocaldb ||
                                            resEcdbDetailsFromLocaldb ||
                                            message ||
                                            buttonAdpDisable ||
                                            !disableAdp ||
                                            // !disableEmployeeLevelAdp||
                                            adpDisableddData
                                            //  ||
                                            // insertDisable
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                      {disableAdp &&
                        !disableEmployeeLevelAdp && ( //true true
                          <>
                            {!isEdit && (
                              <>
                                {insertDisable == false && (
                                  <>
                                    {selectedItem == 'W2' && (
                                      <div>
                                        <button
                                          className='btn btn-sm btn-primary'
                                          disabled={
                                            resProspDetailsFromLocaldb ||
                                            resEcdbDetailsFromLocaldb ||
                                            message ||
                                            // buttonAdpDisable || //checkadpfn
                                            !disableAdp
                                            // adpDisableddData
                                            //  ||
                                            // insertDisable
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      {!disableAdp && (
                        <>
                          {!isEdit && (
                            <>
                              {insertDisable == false && (
                                <>
                                  {selectedItem == 'W2' && (
                                    <div>
                                      <button
                                        className='btn btn-sm btn-primary'
                                        disabled={
                                          resProspDetailsFromLocaldb ||
                                          resEcdbDetailsFromLocaldb ||
                                          message ||
                                          adpDisableddData
                                          //  ||
                                          // insertDisable
                                        }
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!isEdit && (
                        <>
                          {insertDisable == false && (
                            <>
                              {selectedItem !== 'W2' && (
                                <div>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    disabled={
                                      resProspDetailsFromLocaldb ||
                                      resEcdbDetailsFromLocaldb ||
                                      message ||
                                      selectedItem == ''
                                        ? true
                                        : false || adpDisableddData
                                      //  ||
                                      // insertDisable
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {disableAdp &&
                        disableEmployeeLevelAdp && ( //true true
                          <>
                            {isEdit && (
                              <>
                                {(location.state || props.state) && (
                                  <>
                                    <div>
                                      <button
                                        className='btn btn-sm btn-primary'
                                        disabled={
                                          resProspDetailsFromLocaldb ||
                                          resEcdbDetailsFromLocaldb ||
                                          message ||
                                          buttonAdpUpdateDisable ||
                                          adpDisableddData
                                          // buttonAdpDisableUpdate
                                          //  ||
                                          // insertDisable
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                      {disableAdp &&
                        !disableEmployeeLevelAdp && ( //true true
                          <>
                            {isEdit && (
                              <>
                                {(location.state || props.state) && (
                                  <>
                                    <div>
                                      <button
                                        className='btn btn-sm btn-primary'
                                        disabled={
                                          resProspDetailsFromLocaldb ||
                                          resEcdbDetailsFromLocaldb ||
                                          message
                                          // buttonAdpUpdateDisable
                                          // buttonAdpDisableUpdate
                                          //  ||
                                          // insertDisable
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      {!disableAdp && (
                        <>
                          {isEdit && (
                            <>
                              {(location.state || props.state) && (
                                <>
                                  <div>
                                    <button
                                      className='btn btn-sm btn-primary'
                                      disabled={
                                        resProspDetailsFromLocaldb ||
                                        resEcdbDetailsFromLocaldb ||
                                        message ||
                                        adpDisableddData
                                        // buttonAdpUpdateDisable
                                        // buttonAdpDisableUpdate
                                        //  ||
                                        // insertDisable
                                      }
                                    >
                                      Update
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {isEdit && (
                        <>
                          {!(location.state || props.state) && (
                            <>
                              {insertDisable == true && (
                                <div>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    disabled={
                                      resProspDetailsFromLocaldb ||
                                      resEcdbDetailsFromLocaldb ||
                                      message
                                      // || insertDisable
                                      // buttonAdpDisable
                                    }
                                  >
                                    Update
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {isLogin == 'Approver' && (
                        <div>
                          <button
                            type='button'
                            className='btn btn-danger btn-sm cursor-pointer '
                            id='kt_toolbar_primary_button'
                            // onClick={() => navigate(-1)}
                            onClick={handleClick33}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      {!isLogin && (
                        <div>
                          <button
                            type='button'
                            className='btn btn-danger btn-sm cursor-pointer '
                            id='kt_toolbar_primary_button'
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  )}{' '}
                </form>
              </>{' '}
            </Tab>
            <Tab eventKey='project' title='Project Details' disabled={!insertDisable}>
              {/* {insertDisable == true && ( */}
              <div className=' mb-6'>
                <div className=''>
                  {/* <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Project Details </h3> */}

                  <div className='row g-3 '>
                    {insertDisable && mainTableData?.length > 0 ? (
                      <>
                        {mainTableData?.map((each: any, index) => (
                          <div className='col-xs-12 col-md-6 col-lg-4'>
                            <div className='card card-custom card-flush h-100'>
                              <div className='card-body p-4 py-5'>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span className='badge rounded-pill bg-primary'>
                                          {/* {each.is_placement_project && each.is_placement_project
                                            ? 'Placement'
                                            : 'Bench'} */}
                                          {each.is_placement_project
                                            ? each.is_placement_project == 1
                                              ? 'Placement'
                                              : each.is_placement_project == 2
                                              ? 'Bench'
                                              : each.is_placement_project == 3
                                              ? 'Internal'
                                              : '-'
                                            : '-'}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>Name </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold text-capitalize'>
                                          :{/* {each?.project_vendors[0]?.id} */}
                                          {ValidateAdpByConsultantName ? (
                                            <span>{AdpConsultantname}</span>
                                          ) : each?.full_name ? (
                                            <span>
                                              {each.full_name}
                                              {/* <span className='text-primary'>
                                                  {each.is_placement_project
                                                    ? ' Placement'
                                                    : ' Bench'}
                                                </span> */}
                                            </span>
                                          ) : (
                                            '-'
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          End Customer{' '}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          :{' '}
                                          {/* {each.end_customer_name ? each.end_customer_name : '-'} */}
                                          {/* {each.is_placement_project == false */}
                                          {each.is_placement_project == 2 ||
                                          each.is_placement_project == 3
                                            ? localStorage.getItem('org_name')
                                            : each?.end_customer_name}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          Project Name{' '}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          : {each.project_name ? userName(each.project_name) : '-'}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          Project Status{' '}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          :{' '}
                                          {each.project_status
                                            ? each.project_status == 'IN_PROGRESS'
                                              ? 'In Progress'
                                              : each.project_status == 'INITIATED'
                                              ? 'Initiated'
                                              : each.project_status == 'CANCELLED'
                                              ? 'Cancelled'
                                              : each.project_status == 'COMPLETED'
                                              ? 'Completed'
                                              : each.project_status == 'REJECTED'
                                              ? 'Rejected'
                                              : '-'
                                            : '-'}
                                        </div>
                                      </td>
                                    </tr>
                                    {/* {each.is_placement_project == true && ( */}
                                    {each.is_placement_project == 1 && (
                                      <tr>
                                        <td>
                                          {' '}
                                          <div className='fs-5 fw-regular text-gray-600'>
                                            Placement Code{' '}
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-gray-800 fw-semibold'>
                                            : {each.placement_code ? each.placement_code : '-'}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          Placement Type{' '}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          :{' '}
                                          {each.placement_type
                                            ? each.placement_type == 'Disabled'
                                              ? 'NA'
                                              : each.placement_type
                                            : '-'}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          Start Date <span className='fs-9'>(MM/DD/YYYY)</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          :{' '}
                                          {each.joining_date
                                            ? moment(each.joining_date).format('MM/DD/YYYY')
                                            : each.start_date
                                            ? moment(each.start_date).format('MM/DD/YYYY')
                                            : '-'}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          End Date <span className='fs-9'>(MM/DD/YYYY)</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          :{' '}
                                          {each.end_date
                                            ? moment(each.end_date).format('MM/DD/YYYY')
                                            : '-'}
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        {' '}
                                        <div className='fs-5 fw-regular text-gray-600'>
                                          Direct Customer Engagement{' '}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-gray-800 fw-semibold'>
                                          : {each.direct_customer_engagement == true ? 'Yes' : 'No'}
                                        </div>
                                      </td>
                                    </tr>

                                    {each.direct_customer_engagement == false && (
                                      <tr>
                                        <td>
                                          {' '}
                                          <div className='fs-5 fw-regular text-gray-600'>
                                            Client Name{' '}
                                          </div>
                                        </td>
                                        <td>
                                          <div className='text-gray-800 fw-semibold'>
                                            :{' '}
                                            {each.project_vendors &&
                                            each.project_vendors[0] &&
                                            each.project_vendors[0].client_name
                                              ? each.project_vendors[0].client_name
                                              : '-'}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className='col-xs-12 col-md-6 col-lg-4'>
                        <div className='card card-custom card-flush h-100'>
                          <div className='card-body p-4 py-5'>
                            <div className='fs-5 fw-semibold text-gray-700'>
                              No Project Found For This User
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* {(responseData == "Projects not found") || (responseData1 == "No Records Found") && (
                  <h1>Projects not found For This User</h1>
                )} */}
                </div>
              </div>
              {/* )} */}
            </Tab>
            <Tab eventKey='document' title='Document Details' disabled={!insertDisable}>
              {key == 'document' && (
                <AddDocument IndexState={location.state || props.state} id={userIdStore} />
              )}
            </Tab>

            {window.location.pathname === '/timetracker/masters/manageconsultants/addconsultant'
              ? ''
              : (numArray?.includes('relationshipmanager') ||
                  numArray?.includes('admin') ||
                  (numArray?.includes('hr') ||
                  numArray?.includes('approver') ||
                  numArray?.includes('accounts')
                    ? ViewEdit == true
                    : '')) && (
                  <Tab eventKey='comments' title='Correspondence Details' disabled={!insertDisable}>
                    {key == 'comments' && (
                      <CorrespondenceDetails IndexState={location.state || props.state} />
                    )}
                  </Tab>
                )}
          </Tabs>
        </>
      ) : (
        <h1>load</h1>
      )}
    </>
  )
}

export default AddConsultant
